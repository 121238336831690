import * as Bluebird from 'bluebird';
import { Component, Input, OnInit, ChangeDetectorRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormBuilderField, BaseFormBuilderFieldComponent } from 'app/shared/components/form-builder';
import { AppService } from 'app/app.service';
import { from as fromPromise } from 'rxjs';
import { AssetGroupingService } from 'app/services';
import { KuiTreeSelectNode, KuiTreeSelectComponent } from 'app/key-ui/tree-select/tree-select.component';
import { TreeNode } from '@ali-hm/angular-tree-component';
import { KuiDropdownComponent } from 'app/key-ui/dropdown/dropdown.component';
import { TranslateService } from '@ngx-translate/core';
import { EventFilterService } from 'app/services/events/event-filter.service';
import { AssetListItem } from '@key-telematics/fleet-api-client';
import { EMPTY_GUID } from '../../admin/entities/utils';
import { escapeRqlValue } from 'app/shared/utils/rql';

interface TagItem {
    id: string;
    name: string;
    filter: any;
}

@Component({
    selector: 'key-form-builder-asset-filter-field',
    templateUrl: './assetfilter.component.html',
    styleUrls: ['./assetfilter.component.scss'],
})
export class KeyFormBuilderAssetFilterFieldComponent implements OnInit, BaseFormBuilderFieldComponent {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    touched = false;
    dirty = false;
    isLoading = false;
    overlay: boolean;
    ownerId: string;
    truncatedList: { count: number, limit: number };

    selectedItems: TagItem[] = [];
    autoCompleteItems: TagItem[] = [];
    assetsCache: { [key: string]: Promise<AssetListItem[]> } = {};

    @Output() onChange: EventEmitter<{ value: TagItem[], dirty: boolean }> = new EventEmitter();

    groups: Bluebird<TagItem[]>;
    treeNodes: KuiTreeSelectNode[];

    @ViewChild(KuiDropdownComponent) filterDropdown: KuiDropdownComponent;
    @ViewChild(KuiTreeSelectComponent) tree: KuiTreeSelectComponent;

    constructor(
        private app: AppService,
        public grouping: AssetGroupingService,
        private i18n: TranslateService,
        private filters: EventFilterService,
        private ref: ChangeDetectorRef
    ) {
    }

    validate(): boolean {
        this.dirty = true;
        this.touched = true;
        this.ref.markForCheck();
        return !this.field.required || this.selectedItems.length > 0;
    }

    async ngOnInit() {

        this.ownerId = (this.field.options && this.field.options.ownerId) || this.app.client.id || this.app.user.owner.id;
        const allowedAssetTypes: string[] = (this.field.options && this.field.options['allowedAssetTypes']) || [];

        if (this.field.max === 1) { // if max is one we go into single asset selection mode
            const id = this.values[this.field.id] = this.field.getValue ? this.field.getValue(this.field, this.values) : this.values[this.field.id] || this.field.value;

            if (id && id !== EMPTY_GUID) {
                this.isLoading = true;
                this.app.api.entities.getAsset(id).then(asset => {
                    this.selectedItems = [{
                        id: asset.id,
                        name: asset.name,
                        filter: null,
                    }];
                    this.isLoading = false;
                    this.ref.markForCheck();
                });
            } else {
                this.selectedItems = [];
            }

            const assets = await this.getAssets(100);
            if (assets) {
                this.treeNodes = assets.map(x => ({
                    id: x.id,
                    name: x.name,
                    children: null,
                    data: {
                        actorId: x.id,
                        text: x.name,
                    },
                }));
            }
        } else {
            this.values[this.field.id] = this.field.getValue ? this.field.getValue(this.field, this.values) : this.values[this.field.id] || this.field.value || [];

            this.selectedItems = this.values[this.field.id].map(x => ({
                id: x.key || x.id || x.actorId,
                name: x.text || x.name,
                filter: x,
            }));

            setTimeout(() => { // avoid ExpressionChangedAfterItHasBeenCheckedError
                this.field.description = this.field.description || this.i18n.instant('FORMS.ASSETFILTER.DESCRIPTION');
                this.ref.markForCheck();
            });

            this.isLoading = true;
            this.groups = Bluebird.all([
                this.grouping.getCostCentres(this.ownerId),
                this.grouping.getAssetTypes(this.ownerId),
                this.grouping.getAssetGroups(this.ownerId),
                this.grouping.getAssetCategories(this.ownerId),
            ]).then(([costCentres, assetTypes, assetGroups, assetCategories]) => {
                const costCentreTree = AssetGroupingService.toTree(costCentres);
                const userCostCentre = costCentres.find(centre => centre.id === this.app.user.costCentre?.id) || costCentres.find(centre => centre.parent === 'root');
                const filteredCCTree = this.grouping.findCCInCCTree(costCentreTree, userCostCentre);
                const filteredCostCentres = this.grouping.getFilteredCostCentres(filteredCCTree, costCentres);

                let validAssetCategories = assetCategories;
                if (this.field?.options?.excludeCategories) {
                    validAssetCategories = [];
                }
                this.treeNodes = this.filters.buildAssetSelectionTree(filteredCostCentres, assetTypes, assetGroups, validAssetCategories, allowedAssetTypes);
                    
                const groups: TagItem[] = [];

                const addAssetTypeGroups = (assetType: { id: string, name: string }) => {

                    const assetTypeName = this.i18n.instant(`SHARED.ASSET_TYPES.${assetType.name.toUpperCase().replace(/ /g, '_')}`);
                    const anyDesc = this.i18n.instant('FORMS.ASSETFILTER.GROUP_DESC.ANY', { assetType: assetTypeName });

                    groups.push({
                        id: 'any.' + assetType.id,
                        name: anyDesc,
                        filter: {
                            key: 'any.' + assetType.id,
                            actorId: '00000000-0000-0000-0000-000000000000',
                            actorSelectionType: 'any',
                            actorType: 'asset',
                            actorTypeId: assetType.id,
                            actorTypeName: assetTypeName,
                            name: anyDesc,
                            text: anyDesc,
                        },
                    });

                    costCentres.forEach(item => {
                        const desc = this.i18n.instant('FORMS.ASSETFILTER.GROUP_DESC.COST_CENTRE', { assetType: assetTypeName, name: item.name });

                        groups.push({
                            id: assetType.id + '-' + item.id,
                            name: desc,
                            filter: {
                                key: assetType.id + '-' + item.id,
                                actorId: item.id,
                                actorName: item.name,
                                actorSelectionType: 'accessGroup',
                                actorType: 'asset',
                                actorTypeId: assetType.id,
                                actorTypeName: assetTypeName,
                                id: item.id,
                                name: item.name,
                                text: desc,
                            },
                        });
                    });

                    assetGroups.forEach(item => {
                        const desc = this.i18n.instant('FORMS.ASSETFILTER.GROUP_DESC.GROUP', { assetType: assetTypeName, name: item.name });

                        groups.push({
                            id: assetType.id + '-' + item.id,
                            name: desc,
                            filter: {
                                key: assetType.id + '-' + item.id,
                                actorId: item.id,
                                actorName: item.name,
                                actorSelectionType: 'group',
                                actorType: 'asset',
                                actorTypeId: assetType.id,
                                actorTypeName: assetTypeName,
                                id: item.id,
                                name: item.name,
                                text: desc,
                            },
                        });
                    });

                    validAssetCategories.forEach(item => {
                        const desc = this.i18n.instant('FORMS.ASSETFILTER.GROUP_DESC.CATEGORY', { assetType: assetTypeName, name: item.name });

                        groups.push({
                            id: assetType.id + '-' + item.id,
                            name: desc,
                            filter: {
                                key: assetType.id + '-' + item.id,
                                actorId: item.id,
                                actorName: item.name,
                                actorSelectionType: 'category',
                                actorType: 'asset',
                                actorTypeId: assetType.id,
                                actorTypeName: assetTypeName,
                                id: item.id,
                                name: item.name,
                                text: desc,
                            },
                        });
                    });

                };

                if (allowedAssetTypes.includes('asset')) {
                    addAssetTypeGroups({ id: EMPTY_GUID, name: 'Asset' });
                }

                assetTypes.sort((a, b) => a.name.localeCompare(b.name));
                assetTypes.forEach(assetType => {
                    // if the allowed asset types option is not specified, fall back to showing all asset types
                    if (!allowedAssetTypes || allowedAssetTypes.length === 0 || allowedAssetTypes.includes(assetType.name.toLowerCase())) {
                        addAssetTypeGroups(assetType);
                    }
                });

                this.isLoading = false;
                return groups;
            });
        }
    }

    updateValues() {
        this.dirty = true;
        this.touched = true;

        if (this.field.max === 1) {
            if (this.field.setValue) {
                this.field.setValue(this.field, this.values, this.selectedItems[0]);
            } else {
                this.values[this.field.id] = this.selectedItems[0] && this.selectedItems[0].id;
            }
        } else {
            if (this.field.setValue) {
                this.field.setValue(this.field, this.values, this.selectedItems);
            } else {
                this.values[this.field.id] = this.selectedItems.map(x => x.filter);
            }
        }

        this.onChange.emit({ value: this.values[this.field.id], dirty: this.dirty });
    }

    addTag(value: TagItem) {
        this.selectedItems = this.field.max === 1 ? [value] : [...this.selectedItems, value];
        this.updateValues();
    }

    removeTag(value: TagItem) {
        const idx = this.selectedItems.findIndex(x => x.id === value.id);
        this.selectedItems.splice(idx, 1);
        this.updateValues();
    }

    updateAutoCompletionList(searchTerm: string) {
        const filter = searchTerm ? escapeRqlValue(searchTerm) : '';

        this.isLoading = true;
        this.dirty = true;

        return fromPromise(
            Promise.all([
                this.groups,
                this.getAssets(10, filter),
            ]).then(([groups, assets]) => {
                const staticResults = groups ? groups.filter(x => {
                    return searchTerm.split(' ').reduce((p, word) => {
                        return p + (x.name.toLowerCase().indexOf(word.toLowerCase()) !== 1 ? 1 : 0);
                    }, 0);
                }) : [];

                this.autoCompleteItems = assets.map(x => {
                    const assetTypeName = this.i18n.instant(`SHARED.ASSET_TYPES.${x.assetType.name.toUpperCase().replace(/ /g, '_')}`);
                    return {
                        id: x.id,
                        name: x.name,
                        filter: {
                            actorId: x.id,
                            actorName: x.name,
                            actorSelectionType: 'specific',
                            actorType: 'asset',
                            actorTypeId: x.assetType.id,
                            actorTypeName: assetTypeName,
                            id: x.id,
                            name: x.name,
                            text: `${assetTypeName} "${x.name}"`,
                        },
                    };
                })
                    .concat(staticResults)
                    .filter(x => !this.selectedItems.find(y => y.id === x.id) && x.filter.text.toLowerCase().includes(searchTerm.toLowerCase()));

                this.isLoading = false;
                this.ref.markForCheck();

                return this.autoCompleteItems;
            }).catch(err => {
                console.error(err);
                return [];
            })
        );
    }


    nodeSelected(event: { eventName: string, node: TreeNode }) {
        this.dirty = true;

        const { data, id } = event.node.data;
        if (data && !this.selectedItems.find(x => x.id === id)) {
            this.addTag({
                id: id,
                name: data.text,
                filter: data,
            });
            if (event.eventName === 'activate') {
                // single select mode still remembers the selection state
                // we want to clear that after activation rather
                this.tree.clearSelection();
            }
            this.filterDropdown.toggle();
        }
    }

    getAssets(limit: number, filter: string = ''): Promise<AssetListItem[]> {
        const id = `${filter}/${limit}`;
        this.assetsCache[id] = this.assetsCache[id] || this.app.api.entities.listAssets(this.ownerId, 0, limit, 'name:asc', `owner.id=${this.ownerId}&state=active` + (!!filter.length ? `&name=*${filter}*` : '')).then(res => {
            this.truncatedList = this.field.max === 1 && res.count > limit && { limit, count: res.count };
            return res.items;
        });
        return this.assetsCache[id];
    }
}
