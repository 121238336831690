import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, Signal, ViewChild, WritableSignal, computed, input, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatchMediaServiceModule } from 'app/services/match-media/match-media.module';
import { addDays, endOfDay, endOfToday, formatISO, parseISO, startOfDay } from 'date-fns';
import { CalendarModule } from 'primeng/calendar';
import { KuiActionModule } from '../action';
import { KuiButtonModule } from '../button';
import { KuiDropdownModule } from '../dropdown';
import { KuiDropdownComponent } from '../dropdown/dropdown.component';
import { KuiIconModule } from '../icon';
import { KuiListModule } from '../list';
import { DateRangeChangeEvent, TimePeriod } from './daterange.model';
import { EarliestDatePipe } from './earliest-date.pipe';
import { LatestDatePipe } from './latest-date.pipe';
import { MatchMediaService } from 'app/services';
import { toSignal } from '@angular/core/rxjs-interop';


@Component({
    selector: 'kui-daterange',
    standalone: true,
    imports: [
        CommonModule,
        KuiIconModule,
        FormsModule,
        CalendarModule,
        KuiActionModule,
        KuiListModule,
        TranslateModule,
        MatchMediaServiceModule,
        EarliestDatePipe,
        LatestDatePipe,
        KuiDropdownModule,
        KuiButtonModule
    ],
    templateUrl: './daterange.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KuiDateRangeComponent implements OnInit {

    startDate = input<string>();
    endDate = input<string>();
    min = input<string>();
    max = input<string>();
    dateFormat = input<string>("yyyy-MM-dd HH:mm:ss");
    inputStyle = input<'form' | 'none'>('form');
    disabled = input<boolean>(false);
    hourFormat = input<12 | 24>(24);
    icon = input<string>('calendar');
    timePeriods = input<TimePeriod[]>();
    initialTimePeriod = input<TimePeriod>();

    /**
     * Text to display when no date has been selected
     */
    noDatePlaceholder = input<string>();

    /**
     * Text to use for the submit button
     */
    submitButtonText = input<string>();

    /**
     * The number of days difference that can be selected
     */
    maxDateDiffDays = input<number>();

    /**
     * Specify how to handle time when selecting a date via the calendar
     */
    timeStrategy = input<'user' | 'day-extents'>('user');

    @Output() onChange: EventEmitter<DateRangeChangeEvent> = new EventEmitter();

    @ViewChild('dropdown') kuiDropdown: KuiDropdownComponent;

    useTime = computed(() => this.timeStrategy() === 'user');
    minDate = computed(() => {
        if (this.min()) {
            return parseISO(this.min())
        }
    });
    maxDate = computed(() => {
        if (this.max()) {
            return parseISO(this.max())
        }
    });

    startCalendarDate: Date;
    endCalendarDate: Date;
    maxDiffDate: Date;

    selectedEndDate: WritableSignal<Date | null> = signal(null);
    selectedStartDate: WritableSignal<Date | null> = signal(null);

    showCalendar: WritableSignal<boolean> = signal(false);
    isFormStyle = computed(() => this.inputStyle() === 'form');
    mobileDates: Date[] = [];
    selectedTimePeriod = signal<TimePeriod | null>(null);
    internalTimePeriod = signal<TimePeriod | null>(null);
    isMobile: Signal<boolean>;

    constructor(matchMedia: MatchMediaService) {
        this.isMobile = toSignal(matchMedia.isMobile);
    }

    ngOnInit(): void {
        this.startCalendarDate = this.startDate() ? parseISO(this.startDate()) : undefined;
        this.endCalendarDate = this.endDate() ? parseISO(this.endDate()) : undefined;

        // we have initial values
        if (this.startCalendarDate && this.endCalendarDate) {
            this.confirmDates();
        }
        if (this.initialTimePeriod()) {
            this.selectedTimePeriod.set(this.internalTimePeriod());
            this.handleTimePeriod(this.initialTimePeriod());
        }
    }

    togglePopup() {
        this.kuiDropdown.toggle();
    }

    handleCalendarDateSelection() {
        // prevent situations where end date is before start date
        if (this.endCalendarDate < this.startCalendarDate) {
            this.endCalendarDate = this.startCalendarDate;
        }

        // user has touched the calendar, any selectged time period is now invalid
        if (this.startCalendarDate && this.endCalendarDate) {
            this.internalTimePeriod.set(null);
        }

        // user hasn't selected a start date yet, default to end date
        if (!this.startCalendarDate) {
            this.startCalendarDate = this.endCalendarDate;
        }

        // user hasn't selected an end date yet, default to start date
        if (!this.endCalendarDate) {
            this.endCalendarDate = this.startCalendarDate;
        }

        this.adjustForDateDiff();
        this.adjustForTimeStrategy();
    }

    handleMobileDateSelection() {
        this.internalTimePeriod.set(null);
        this.startCalendarDate = this.mobileDates[0];
        if (this.mobileDates[1]) {
            this.endCalendarDate = this.mobileDates[1];
        } else {
            // no end date selected, use the start date as the end
            this.endCalendarDate = this.startCalendarDate;
        }
        this.adjustForDateDiff();
        this.adjustForTimeStrategy();
    }

    handleTimePeriod(period: TimePeriod) {
        const { start, end } = period.getDateRange(this.endDate() ?? formatISO(endOfToday()));
        this.startCalendarDate = parseISO(start);
        this.endCalendarDate = parseISO(end);
        this.internalTimePeriod.set(period);

        if (this.isMobile()) {
            this.mobileDates = [this.startCalendarDate, this.endCalendarDate];
        }
        
        if (!this.useTime()) {
            // time is not user specified, let's just apply this period instead.
            this.confirmDates();
        }
    }

    confirmDates() {
        this.selectedStartDate.set(this.startCalendarDate);
        this.selectedEndDate.set(this.endCalendarDate);
        this.selectedTimePeriod.set(this.internalTimePeriod());

        this.onChange.emit({
            start: formatISO(this.selectedStartDate()),
            end: formatISO(this.selectedEndDate())
        });

        this.kuiDropdown?.toggle(false);
    }

    /**
     * Adjust dates depending on if a maximum date difference value is set
     */
    private adjustForDateDiff() {
        if (this.maxDateDiffDays()) {
            this.maxDiffDate = addDays(this.startCalendarDate, this.maxDateDiffDays());
            if (this.endCalendarDate > this.maxDiffDate) {
                this.endCalendarDate = this.maxDiffDate;
            }
        }
    }

    /**
     * Adjust the time of selected dates depending on the specified time strategy
     */
    private adjustForTimeStrategy() {
        if (this.timeStrategy() === 'day-extents') {
            if (this.startCalendarDate) {
                this.startCalendarDate = startOfDay(this.startCalendarDate);
            }
            if (this.endCalendarDate) {
                this.endCalendarDate = endOfDay(this.endCalendarDate);
            }
        }
    }

}
