import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KuiModule } from 'app/key-ui';
import { PipeModule } from 'app/shared/pipes/pipe.module';
import { GetKeyPipe } from 'app/shared/pipes/getkey.pipe';
import { FormsModule } from '@angular/forms';
import { MediaViewerModalComponent } from './viewer-modal/viewer-modal.component';
import { VideoThumbnailComponent } from './video-thumbnail/video-thumbnail.component';
import { VideoComponent } from './video/video.component';
import { MediaLiveViewModalComponent } from './video-live-modal/live-modal.component';
import { VjsPlayerComponent } from './players/vjs-player/vjs-player.component';

const COMPONENTS = [
    MediaViewerModalComponent,
    MediaLiveViewModalComponent,
    VideoThumbnailComponent,
    VideoComponent,
    VjsPlayerComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        KuiModule,
        TranslateModule.forChild(),
        PipeModule.forRoot(),
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS,
    providers: [GetKeyPipe]
})
export class KeyMediaModule { }
