<div class="form-control py-2 px-3"
    [class.border-danger]="error || (field.required && touched && !completed())">

    <!-- ACTOR -->
    <span *ngIf="hasActor">
        {{ 'SHARED.ALERTS.CONDITIONS.GENERAL.WHEN' | translate }}
        <kui-dropdown #actorDropdown
            [style]="'none'"
            [customContent]="actorDropdownContent"
            [customContentContext]="{ component: actorDropdown }">
            <a [class.unset]="!item.actor">{{ item.actor ? item.actor.text : 'SHARED.ALERTS.CONDITIONS.GENERAL.ACTOR' | translate }}&nbsp;</a>
        </kui-dropdown>
        <ng-template #actorDropdownContent
            let-component="component">
            <div style="min-width: 200px">
                <key-form-builder-eventfilter-searchable-tree entityType="asset"
                    [ownerId]="ownerId"
                    [nodes]="actorNodes"
                    (onChange)="onActorSelected(component, $event)"></key-form-builder-eventfilter-searchable-tree>
            </div>
        </ng-template>
    </span>

    <!-- ACTION -->
    <span>
        <kui-dropdown #actionDropdown
            [style]="'none'"
            [customContent]="actionDropdownContent"
            [customContentContext]="{ component: actionDropdown }">
            <a [class.unset]="!item.action">{{ item.action ? item.action.text : 'SHARED.ALERTS.CONDITIONS.GENERAL.ACTION' | translate }}&nbsp;</a>
        </kui-dropdown>
        <ng-template #actionDropdownContent
            let-component="component">
            <div style="min-width: 200px">
                <kui-tree-select [nodes]="actionNodes"
                    (selectedNodeChanged)="onActionSelected(component, $event)"></kui-tree-select>
            </div>
        </ng-template>
    </span>

    <!-- TARGET -->
    <span *ngIf="item.action && item.action.targetType">
        <kui-dropdown #targetDropdown
            [style]="'none'"
            [customContent]="targetDropdownContent"
            [customContentContext]="{ component: targetDropdown }">
            <a [class.unset]="!item.target">{{ item.target ? item.target.text : ('SHARED.ALERTS.CONDITIONS.GENERAL.TARGET' | translate) }}&nbsp;</a>
        </kui-dropdown>
        <ng-template #targetDropdownContent
            let-component="component">
            <div style="min-width: 200px">
                <key-form-builder-eventfilter-searchable-tree [entityType]="item.action.targetType"
                    [ownerId]="ownerId"
                    [nodes]="targetNodes"
                    (onChange)="onTargetSelected(component, $event)"></key-form-builder-eventfilter-searchable-tree>
            </div>
        </ng-template>
    </span>

    <div class="pl-3">
        <div *ngFor="let condition of conditions; let i = index"
            class="d-flex pt-2">
            <div class="flex-1">
                {{ filters.getConditionPrefix(i) }}
                <span *ngFor="let part of condition.parts"
                    [ngSwitch]="part.type">

                    <span *ngSwitchCase="'text'">{{part.text}}&nbsp;</span>

                    <kui-dropdown *ngSwitchCase="'dropdown'"
                        #inlineDropdown
                        [style]="'none'"
                        [customContent]="inlineDropdownContent"
                        [customContentContext]="{ component: inlineDropdown }">
                        <a [class.unset]="!part.value">{{part.text}}&nbsp;</a>
                    </kui-dropdown>
                    <ng-template #inlineDropdownContent
                        let-component="component">
                        <div style="min-width: 200px">
                            <key-form-builder-eventfilter-searchable-tree [entityType]="part.key"
                                [ownerId]="ownerId"
                                [nodes]="part.values"
                                (onChange)="onConditionOptionSelected(component, $event, condition, part)"></key-form-builder-eventfilter-searchable-tree>
                        </div>
                    </ng-template>

                    <kui-dropdown *ngSwitchCase="'checklist'"
                        #inlineChecklist
                        [style]="'none'"
                        [menu]="part.values">
                        <a [class.unset]="!part.value">{{formatText(part.text)}}&nbsp;</a>
                    </kui-dropdown>

                    <kui-dropdown *ngSwitchDefault
                        #editorDropdown
                        [style]="'none'"
                        [customContent]="editorDropdownContent"
                        [customContentContext]="{ component: editorDropdown }">
                        <a [class.unset]="!part.value">{{part.text}}&nbsp;</a>
                    </kui-dropdown>
                    <ng-template #editorDropdownContent
                        let-component="component">
                        <div class="pl-2 d-flex justify-content-center align-items-center">
                            <div class="flex-1 nowrap">

                                <input [class.hidden]="part.type !== 'value'"
                                    class="d-inline-block"
                                    kui-input
                                    #valueInput
                                    [value]="part.value || ''"
                                    (change)="onConditionEditorUpdated(component, valueInput.value, condition, part)"
                                    style="width: 100px"
                                    type="text" />

                                <input [class.hidden]="part.type !== 'speed' && part.type !== 'distance' && part.type !== 'duration'"
                                    class="d-inline-block"
                                    kui-input
                                    #numberInput
                                    [value]="part.value"
                                    (change)="onConditionEditorUpdated(component, numberInput.value || timeInput.value, condition, part)"
                                    style="width: 70px"
                                    type="number" />

                                <input [class.hidden]="part.type !== 'time'"
                                    class="d-inline-block"
                                    kui-input
                                    #timeInput
                                    placeholder="00:00"
                                    [value]="part.value || ''"
                                    (change)="onConditionEditorUpdated(component, numberInput.value || timeInput.value, condition, part)"
                                    style="width: 100px"
                                    type="time" />

                                {{part.unit}}
                            </div>
                            <kui-icon kui-action
                                class="mx-2"
                                name="check-circle"
                                size="sm"
                                color="primary"
                                (click)="component.toggle(false)"></kui-icon>
                        </div>
                    </ng-template>


                </span>

            </div>
            <div>
                <kui-icon kui-action
                    class="muted pl-2"
                    name="times"
                    size="sm"
                    (click)="onRemoveCondition(condition.data)"></kui-icon>
            </div>
        </div>
        <div class="pt-2"
            *ngIf="conditionNodes">
            <kui-dropdown #conditionDropdown
                [style]="'none'"
                [customContent]="conditionDropdownContent"
                [customContentContext]="{ component: conditionDropdown }">
                <a class="unset">
                    {{ filters.getConditionPrefix(conditions.length) }}...
                </a>
            </kui-dropdown>
            <ng-template #conditionDropdownContent
                let-component="component">
                <div style="min-width: 200px">
                    <kui-tree-select [nodes]="conditionNodes"
                        (selectedNodeChanged)="onConditionSelected(component, $event)"></kui-tree-select>
                </div>
            </ng-template>
        </div>
    </div>

</div>

<div class="d-flex">

    <div class="flex-1 invalid-feedback d-block">
        <div *ngIf="field.required && touched && !completed()">{{ 'FORMS.SHARED.INCOMPLETE' | translate:{ field: field.title } }}</div>
        <div *ngIf="error">{{ error }}</div>
    </div>

</div>