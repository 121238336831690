import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { KuiModule } from 'app/key-ui';

import {
    KeyLayoutGridModule,
    KeyLayoutViewModule,
    KeyFeedModule,
    KeyListingModule,
    ThemeComponent,
    ViewportComponent,
    KeyMapModule,
    KeyCardsModule,
    KeyFormBuilderModule
} from './components';
import { KeyAnalyticsModule } from './components/analytics';
import { KeySelectionModule } from './components/selection/selection.module';
import { KeyContentContainerDirective } from './directives';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from './pipes/pipe.module';
import { KeyOptionsSidebarModule } from './components/options-sidebar/options-sidebar.module';
import { KeyContactsModule } from './components/contacts/contacts.module';
import { KeyMediaModule } from './components/media/media.module';
import { KeyGraphModule } from './components/graph/graph.module';
import { KeyImageEditorModule } from './components/image-editor/image-editor.module';
import { KeyModalModule } from './components/modal';
import { KeyDashboardsModule } from './components/dashboards/dashboards.module';
import { NavPageLinkModule } from './directives/page-nav-link.module';
import { KeySearchResultsListModule } from './components/search-results-list/search-results-list.module';
import { VideoListComponent } from './components/media/video-list/list.component';
import { VideoListItemComponent } from './components/media/video-list-item/list-item.component';
import { KeyDatesModule } from './components/dates/dates.module';

const MODULES = [
    KeyListingModule,
    KeyLayoutGridModule,
    KeyFeedModule,
    KeyMapModule,
    KeySelectionModule,
    KeyCardsModule,
    KeyFormBuilderModule,
    KeyOptionsSidebarModule,
    KeyContactsModule,
    KeyMediaModule,
    KeyAnalyticsModule,
    KeyGraphModule,
    KeyImageEditorModule,
    KeyModalModule,
    KeyDashboardsModule,
    KeyLayoutViewModule,
    NavPageLinkModule,
    KeySearchResultsListModule,
    KeyDatesModule,
];

const COMPONENTS = [
    ViewportComponent,
    ThemeComponent,
    VideoListComponent,
    VideoListItemComponent,
];

const DIRECTIVES = [
    KeyContentContainerDirective,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        KuiModule,
        PipeModule.forRoot(),
        ...MODULES,
        TranslateModule,
    ],
    exports: [
        ...COMPONENTS,
        ...DIRECTIVES,
        ...MODULES,
        TranslateModule,
        PipeModule,
    ],
    declarations: [...COMPONENTS, ...DIRECTIVES],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [],
        };
    }
}
