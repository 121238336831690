import { Component, Input, Output, OnInit, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { AppService } from 'app/app.service';
import { BaseComponent } from 'app/shared/components/base/base.component';
import { get } from 'lodash';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';

export interface KuiTabBarTitleID {
    title: string;
    id: string;
    icon?: string;
}

export interface KuiTabBarDragEvent {
    result: string[];
    id: string;
}

@Component({
    selector: 'kui-tab-bar',
    templateUrl: './tab-bar.component.html',
})

export class KuiTabBarComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
    private dropSubscription: Subscription;
    _items: KuiTabBarTitleID[];

    @Input() items: Array<string | KuiTabBarTitleID>;
    @Input() mobile: boolean;
    @Input() selected: string | number;
    @Input() stretchItems: boolean;
    @Input() closeButton: boolean;
    @Input() draggable: boolean;

    @Output() selection = new EventEmitter<string>();
    @Output() reorder = new EventEmitter<KuiTabBarDragEvent>();
    @Output() close = new EventEmitter<string>();

    indicatorStyle = 'indicator-none';

    constructor(app: AppService, private dragula: DragulaService) {
        super();

        this.on(app.theme$, theme => {
            this.indicatorStyle = 'indicator-' + get(theme.settings, 'tab.active.indicator.style', 'none');
        });
    }

    ngOnInit() {
        if (this.draggable) {
            this.dropSubscription = this.dragula.drop('reorder-tabs').subscribe(({ source, el }) => {
                this.reorder.emit({
                    id: el.id,
                    result: Array.from(source.children).filter(item => item.localName === 'li').map(item => item.id),
                });
            });
        }

        // Avoid ExpressionChangedAfterItHasBeenCheckedError by wrapping it in setTimeout
        setTimeout(() => {
            if (!this.selected && this.items.length > 0) {
                this.emitSelection(this._items[0]);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.items) {
            this._items = (this.items || []).map((item) => {
                if (typeof item === 'string') {
                    return { title: item, id: item };
                }
                return item;
            });
        }
    }

    ngOnDestroy() {
        if (this.draggable && this.dropSubscription) {
            this.dropSubscription.unsubscribe();
        }
    }

    emitSelection(item: KuiTabBarTitleID) {
        this.selected = item.id;
        this.selection.emit(item.id);
    }

    emitClose(item: KuiTabBarTitleID, e: Event) {
        e.stopPropagation();
        this.close.emit(item.id);
    }

    isSelected(item: KuiTabBarTitleID): boolean {
        return this.selected === item.id;
    }
}
