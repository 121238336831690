import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { MapOptionService } from '../../map-option.service';
import { GEOFENCE } from '../../model';
import { MapOption } from '../map-option';
import { MapOptionZoneEditorComponent, ZoneEditorConfig } from '../map-option-zone-editor';
import { MapOptionZoneRemoverComponent, ZoneRemoverConfig } from '../map-option-zone-remover';
import { MapOptionZoneSelectorComponent, ZoneSelectorConfig } from '../map-option-zone-selector';
import { MapOptionComponent } from '../map-option.component';

export interface MapOptionZoneConfig {
    zoneAdd: boolean;
    zoneEdit: boolean;
    zoneDelete: boolean;
}

type AvailableZoneConfig = ZoneEditorConfig | ZoneSelectorConfig | ZoneRemoverConfig;

@Component({
    styleUrls: ['./map-option-zones.component.scss'],
    templateUrl: './map-option-zones.component.html',
})
export class MapOptionZonesComponent implements MapOptionComponent<MapOptionZoneConfig>, OnInit {

    static ID = 'map-option-zones-component';
    static ICON = 'draw-polygon';
    static TITLE = 'LEAFLET.MAP_OPTIONS.ZONES';

    data: MapOptionZoneConfig;
    options: MapOption<AvailableZoneConfig>[];
    compact$: Observable<boolean> = this.optionService.isCompact$;

    constructor(private optionService: MapOptionService) { }

    ngOnInit(): void {
        this.options = [
            this.data.zoneEdit && {
                component: MapOptionZoneSelectorComponent,
                data: {
                    selectableTypes: GEOFENCE,
                } as const,
                id: MapOptionZoneSelectorComponent.ID,
                title: 'LEAFLET.ZONES.EDIT_ZONE',
                icon: 'edit'
            },
            this.data.zoneAdd && {
                component: MapOptionZoneEditorComponent,
                data: {
                    editorAction: 'zoneaddpolygon',
                } as const,
                id: MapOptionZoneEditorComponent.ID,
                title: 'LEAFLET.ZONES.NEW_ZONE',
                icon: 'draw-polygon'
            },
            this.data.zoneDelete && {
                component: MapOptionZoneRemoverComponent,
                data: {
                    supportedTypes: GEOFENCE
                },
                id: MapOptionZoneRemoverComponent.ID,
                title: 'LEAFLET.ZONES.REMOVE_ZONE',
                icon: 'trash-can-xmark'
            }
        ].filter(x => x);
    }
}

