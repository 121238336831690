<kui-input-group [cleanAddons]="true"
    class="w-100 d-block">
    <kui-tag-input #tagInputEl
        [invalid]="error || (field.required && (dirty && touched) && selectedItems.length === 0)"
        [placeholder]="'FORMS.ASSETFILTER.PLACEHOLDER' | translate"
        [loading]="isLoading"
        [autoCompleteItems]="autoCompleteItems"
        [remoteSearch]="true"
        [selectedItems]="selectedItems"
        (itemAdded)="addTag($event)"
        (itemRemoved)="removeTag($event)"
        (searchTermChanged)="updateAutoCompletionList($event)"
        [multiple]="field.max !== 1"></kui-tag-input>
    <span *ngIf="!(field.max !== 1) && !!selectedItems?.length"
        (click)="tagInputEl.removeItem(selectedItems[0])"
        kui-input-group-addon
        kui-action
        class="px-1">
        <kui-icon name="times"
            size="xs"
            class="muted"></kui-icon>
    </span>
    <span kui-input-group-addon
        class="p-0"
        [class.border-danger]="error || (field.required && (dirty && touched) && selectedItems.length === 0)"
        [class.overlay]="overlay">
        <kui-dropdown [style]="'none'"
            [customContent]="customDropdown"
            (onOpen)="overlay = true"
            (onClose)="overlay = false">
            <kui-icon [name]="field.max === 1 ? 'chevron-down' : 'plus'"
                size="xs"
                class="py-2 px-3"></kui-icon>
        </kui-dropdown>
        <ng-template #customDropdown>
            <div style="min-width: 200px; max-width: 250px">
                <p *ngIf="truncatedList"
                    class="trucated-message mb-0">
                    {{'FORMS.ASSETFILTER.TRUNCATED_MESSAGE' | translate: truncatedList }}
                </p>
                <kui-tree-select [nodes]="treeNodes"
                    (selectedNodeChanged)="nodeSelected($event)"></kui-tree-select>
            </div>
        </ng-template>
    </span>
</kui-input-group>

<div *ngIf="field.required && (dirty && touched) && selectedItems.length === 0"
    class="invalid-feedback d-block">
    <div>{{ 'FORMS.SHARED.REQUIRED' | translate:{ field: field.title } }}</div>
</div>
<div *ngIf="error"
    class="invalid-feedback d-block">
    <div>{{ error }}</div>
</div>