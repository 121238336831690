import * as Bluebird from 'bluebird';
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FormBuilderField, BaseFormBuilderFieldComponent } from 'app/shared/components/form-builder';
import { KuiDropdownComponent } from 'app/key-ui/dropdown/dropdown.component';
import { KuiTreeSelectNode } from 'app/key-ui/tree-select/tree-select.component';
import { EventFilter, EventFilterCondition } from '@key-telematics/fleet-api-client';
import { AppService } from 'app/app.service';
import { TranslateService } from '@ngx-translate/core';
import { MeasurementUnitsService, AssetGroupingService, EventFilterService } from 'app/services';
import * as moment from 'moment-timezone';
import { cloneDeep } from 'lodash';

interface ConditionPart {
    key: string;
    type: string;
    text: any;
    value?: any;
    values?: any[];
    unit?: string;
    linkedTo?: string;
}

interface Condition {
    text: string;
    data: EventFilterCondition;
    parts: ConditionPart[];
}

@Component({
    selector: 'key-form-builder-eventfilter-field',
    templateUrl: './eventfilter.component.html',
    styleUrls: ['eventfilter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyFormBuilderEventFilterFieldComponent implements BaseFormBuilderFieldComponent, OnInit {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Output() onChange: EventEmitter<{ value: { [key: string]: any }, dirty: boolean }> = new EventEmitter();

    actorNodes: KuiTreeSelectNode[];
    actionNodes: KuiTreeSelectNode[];
    targetNodes: KuiTreeSelectNode[];
    targetNodesType: string;
    eventType: string;
    conditionNodes: KuiTreeSelectNode[];

    conditions: Condition[];
    minConditions = 0; // increase in descendants if you want to enforce a minimum number of conditions
    ownerId: string;

    touched = false;

    filters: EventFilterService;

    get item(): EventFilter {
        const item = this.field.getValue ? this.field.getValue(this.field, this.values) : this.values[this.field.id];
        return item || {};
    }
    set item(val: EventFilter) {
        if (this.field.setValue) {
            const promise = this.field.setValue(this.field, this.values, val);
            if (promise && promise.then) {
                promise.then(() => this.ref.markForCheck());
            }
        } else {
            this.values[this.field.id] = val;
        }
    }

    get hasActor(): boolean {
        return !this.field.options || this.field.options.actor !== false;
    }

    @ViewChild('actorDropdown') dropdown: KuiDropdownComponent;

    constructor(
        public app: AppService,
        protected i18n: TranslateService,
        protected units: MeasurementUnitsService,
        protected grouping: AssetGroupingService,
        protected ref: ChangeDetectorRef
    ) { }

    completed(): boolean {

        if (this.hasActor && !this.item.actor) { return false; }
        if (!this.item.action) { return false; }
        if (this.item.action && this.item.action.targetType && !this.item.target) { return false; }

        if (this.conditions.length < this.minConditions) { return false; }

        let unsetCnt = 0;
        this.conditions.forEach(condition => {
            condition.parts.forEach(part => {
                if (part.type !== 'text' && !part.value) {
                    unsetCnt++;
                }
            });
        });
        
        return unsetCnt === 0;

    }

    validate(): boolean {
        this.touched = true;
        this.ref.markForCheck();
        return !this.field.required || this.completed();
    }

    changed() {
        this.touched = true;
        this.onChange.emit({ value: this.values, dirty: true });
        this.ref.markForCheck();
    }


    formatText(value: string | string[]): string {
        return value ? typeof value === 'string' ? value : value.join(', ') : '';
    }

    async loadFilterService(): Promise<EventFilterService> {
        const filters = new EventFilterService(this.app, this.i18n, this.grouping); // this is not injected as we need to make sure we have our own instance
        filters.setOwnerId(this.ownerId);
        const groupTypes = this.field.options?.groupTypes || null;
        const [assetTree, targetTree] = await Promise.all([
            filters.getAssetSelectionTree(groupTypes),
            filters.getTargetNodes(this.targetNodesType),
        ]);
        this.actorNodes = assetTree;
        this.targetNodes = targetTree;
        return filters;
    }

    async ngOnInit() {

        this.ownerId = (this.field.options && this.field.options.ownerId) || this.app.client.id || this.app.user.owner.id;
        this.values[this.field.id] = this.values[this.field.id] || this.field.value || {};

        this.targetNodesType = this.item && this.item.action && this.item.action.targetType;
        this.filters = await this.loadFilterService();

        this.actionNodes = this.filters.getActionEventsTree();
        this.eventType = this.item && this.item.action && this.item.action.eventType;


        this.refreshConditions();
        if (this.item && this.item.action) {
            this.conditionNodes = this.filters.getConditionsTree(this.item.action);
        }

    }

    async refreshConditions() {
        this.item.conditions = this.item.conditions || [];
        this.conditions = await Promise.all(this.item.conditions.map(condition => this.parseCondition(condition)));
        this.ref.markForCheck();
    }


    onActorSelected(dropdown: KuiDropdownComponent, event: KuiTreeSelectNode) {
        const data = cloneDeep(event.node.data.data);
        this.item = { ...this.item, actor: data as any };
        dropdown.toggle();
        this.changed();
    }

    async onActionSelected(dropdown: KuiDropdownComponent, event: KuiTreeSelectNode) {
        if (event.eventName === 'activate') {
            const data = cloneDeep(event.node.data.data);
            if (data) {
                this.item = { ...this.item, action: data as any };
                if (('targetType' in this.item.action) && data?.targetType === undefined) {
                    // a previous action had a target type,
                    // we must now remove that value as this action doesn't support it.
                    this.item.action.targetType = null;
                }
                this.conditionNodes = this.filters.getConditionsTree(this.item.action);
                if (this.targetNodesType !== data.targetType || this.eventType !== data.eventType) {
                    this.item.target = null;
                    this.targetNodesType = data.targetType;
                    this.eventType = data.eventType;
                    this.targetNodes = await this.filters.getTargetNodes(this.targetNodesType);
                }
                dropdown.toggle();
                this.changed();
            }
        }
    }

    onTargetSelected(dropdown: KuiDropdownComponent, event: KuiTreeSelectNode) {
        const data = cloneDeep(event.node.data.data);
        this.item = { ...this.item, target: data as any };
        dropdown.toggle();
        this.changed();
    }

    async onConditionSelected(dropdown: KuiDropdownComponent, event: KuiTreeSelectNode) {
        if (event.eventName === 'activate') {
            const data = cloneDeep(event.node.data.data);
            dropdown.toggle();
            this.item.conditions.push(data);
            this.refreshConditions();
            this.changed();
        }
    }

    onRemoveCondition(condition: EventFilterCondition) {
        const idx = this.item.conditions.indexOf(condition);
        if (idx > -1) {
            this.item.conditions.splice(idx, 1);
            this.refreshConditions();
            this.changed();
        }
    }

    getConditionText(condition: EventFilterCondition) {
        return this.filters.getConditionText(condition.type, {});
    }


    parseCondition(filterCondition: EventFilterCondition): Bluebird<Condition> {

        const text = this.i18n.instant(`SHARED.ALERTS.CONDITIONS.${filterCondition.type.toUpperCase()}.TEXT`) as string;
        const textParts = text.split(' '); // "time is {mode} {time1} and {time2} on {dow}"

        const condition = {
            text: text,
            data: filterCondition,
            parts: [],
        };

        return Bluebird.map(textParts, async part => {

            if (part.startsWith('{')) {
                const key = part.replace(/[\{\}]/g, '');
                const options = await this.filters.getConditionOptions(filterCondition.type, key, filterCondition.values);
                if (options != null) {

                    const result: ConditionPart = {
                        key: key,
                        type: options.type,
                        text: filterCondition.text[key] || this.i18n.instant(`SHARED.ALERTS.CONDITIONS.${filterCondition.type.toUpperCase()}.${key.toUpperCase()}`),
                        value: filterCondition.values[key],
                        values: options.values as any[],
                        unit: null,
                    };
                    if (options.linkedTo) {
                        result.linkedTo = options.linkedTo;
                    }

                    switch (options.type) {

                        case 'checklist':
                            const vals = filterCondition.values[key] || [];
                            result.values = result.values.map(item => ({
                                text: item.name,
                                type: 'toggle',
                                checked: vals.indexOf(item.id) > -1,
                                action: (event) => this.onConditionToggled(item, event.target.checked, condition, result),
                            }));
                            break;
                        case 'distance':
                            result.unit = this.units.format(0, 'distance').unit;
                            break;
                        case 'duration':
                            result.unit = this.i18n.instant('SHARED.DATETIME.SECONDS').toLowerCase();
                            break;
                        case 'speed':
                            result.unit = this.units.format(0, 'speed').unit;
                            break;
                    }
                    return result;
                }
            }
            return {
                key: '',
                type: 'text',
                text: part,
            };

        }).then((parts) => {
            condition.parts = parts;
            return condition;
        });

    }

    async onConditionOptionSelected(dropdown: KuiDropdownComponent, event: KuiTreeSelectNode, condition: Condition, part: ConditionPart) {
        if (event.eventName === 'activate') {
            const item = event.node.data;

            let id = item.id;
            let text = item.name;

            if (condition.data.type === 'zone' && item.data && item.data.targetTypeId) { // if this is a target filter, create a special kind if id (yes, i know).
                id = `${item.data.targetTypeId}:${item.data.targetSelectionType}:${item.data.targetId}`;
                text = item.data.text;
            }

            if (condition.data.type === 'linked' && item.data && item.data.actorTypeId) { // if this is a actor filter, create a special kind if id (yes, i know).
                id = `${item.data.actorTypeId}:${item.data.actorSelectionType}:${item.data.actorId}`;
                text = item.data.text;
            }


            part.value = id;
            part.text = text;

            condition.data.values[part.key] = part.value;
            condition.data.text[part.key] = part.text;

            if (part.linkedTo) {
                condition.data.values[part.linkedTo] = null;
                condition.data.text[part.linkedTo] = null;
                await this.refreshConditions();
            }

            dropdown.toggle();
            this.changed();
        }
    }

    onConditionEditorUpdated(dropdown: KuiDropdownComponent, value: string, condition: Condition, part: ConditionPart) {

        if (condition.data.type === 'time') { // time editors require some special handling

            if (value.length === 5) {
                value = value + ':00';
            }
            // if the user enters a nonsense time, then just reset it to 00:00:00
            if (!moment(value, 'HH:mm:ss', true).isValid()) {
                value = '00:00:00';
            }

            delete condition.data.values['timeZone'];
            condition.data.values['timeZoneId'] = this.app.user.timeZoneId;
        }

        if (condition.data.type === 'speed' || condition.data.type === 'speedlimit') {
            condition.data.values['multiplier'] = this.units.format(0, 'speed').multiplier;
        }
        if (condition.data.type === 'distance') {
            condition.data.values['multiplier'] = this.units.format(0, 'distance').multiplier;
        }

        part.value = value;
        part.text = value + (part.unit ? ' ' + part.unit : '');

        condition.data.values[part.key] = part.value;
        condition.data.text[part.key] = part.text;
        dropdown.toggle();
        this.changed();

    }

    onConditionToggled(item: { id: string, name: string }, value: boolean, condition: Condition, part: ConditionPart) {

        // this is some really shitty code, but time pressure means it's the best it's gonna get for now
        part.value = part.value || [];
        part.text = Array.isArray(part.text) ? part.text : [];
        if (value) {
            part.value.push(item.id);
            part.text.push(item.name);
        } else {
            part.value.splice(part.value.indexOf(item.id), 1);
            part.text.splice(part.text.indexOf(item.name), 1);
        }

        condition.data.values[part.key] = part.value;
        condition.data.text[part.key] = part.text;
        this.changed();

    }


}
