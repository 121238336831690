import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app.service';
import { AssetGroupingService, EventFilterService, GeoFilterService, MeasurementUnitsService } from 'app/services';
import { KeyFormBuilderEventFilterFieldComponent } from './eventfilter.component';


@Component({
    selector: 'key-form-builder-geofilter-field',
    templateUrl: './eventfilter.component.html',
    styleUrls: ['eventfilter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyFormBuilderGeoFilterFieldComponent extends KeyFormBuilderEventFilterFieldComponent {

    constructor(
        app: AppService,
        i18n: TranslateService,
        units: MeasurementUnitsService,
        grouping: AssetGroupingService,
        ref: ChangeDetectorRef
    ) {
        super(app, i18n, units, grouping, ref);
        this.minConditions = 1; // must have at least one condition to save
    }

    async loadFilterService(): Promise<EventFilterService> {
        const filters = new GeoFilterService(this.app, this.i18n, this.grouping); // this is not injected as we need to make sure we have our own instance
        filters.setOwnerId(this.ownerId);
        return filters;
    }

}
