<div class="tab-bar"
    [ngClass]="indicatorStyle"
    [class.mobile]="mobile">
    <ol [class.stretch]="stretchItems"
        [dragula]="draggable ? 'reorder-tabs' : null">
        <li kui-action
            *ngFor="let item of _items"
            [class.active]="isSelected(item)"
            class="text-truncate"
            [id]="item.id"
            (click)="emitSelection(item)">
            <kui-icon *ngIf="item.icon"
                class="tab-icon pr-1"
                [name]="item.icon"
                ></kui-icon>

            <span class="text-truncate"
                [title]="item.title">{{ item.title }}</span>
            <kui-icon *ngIf="closeButton"
                name="times"
                class="close ml-2"
                (click)="emitClose(item, $event)"></kui-icon>
        </li>
    </ol>
</div>