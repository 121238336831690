import { Component, EventEmitter, OnInit, Output, input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateRangeChangeEvent } from 'app/key-ui/daterange';
import { DateRange } from 'app/shared/utils/dates';
import * as moment from 'moment-timezone';
@Component({
    selector: 'key-date-range-select',
    templateUrl: './date-range-selection.component.html',
})
export class KeyDateRangeSelectComponent implements OnInit {

    currentString = input<string>();
    dateRange = input<DateRange>();
    maxDayRange = input<number>(7);
    minDate = input<string>();
    maxDate = input<string>();

    @Output() onDateSelection = new EventEmitter<DateRange>();

    timePeriods = [
        {
            id: "today",
            text: this.i18n.instant('REPORTING.ITEMS.FIELDS.VALUES.TODAY'),
            getDateRange: () => {
                return {
                    start: moment.utc().tz(moment.defaultZone.name).startOf('day').toISOString(),
                    end: moment.utc().tz(moment.defaultZone.name).endOf('day').toISOString(),
                }
            },
        },
        {
            id: "yesterday",
            text: this.i18n.instant('REPORTING.ITEMS.FIELDS.VALUES.YESTERDAY'),
            getDateRange: () => {
                const yesterday = moment.utc().tz(moment.defaultZone.name).subtract(1, 'days');
                return {
                    start: yesterday.clone().startOf('day').toISOString(),
                    end: yesterday.clone().endOf('day').toISOString(),
                }
            },
        },
        {
            id: "last7Days",
            text: this.i18n.instant('REPORTING.ITEMS.FIELDS.VALUES.LAST_7_DAYS'),
            getDateRange: () => {
                const today = moment.utc().tz(moment.defaultZone.name);
                return {
                    start: today.clone().startOf('day').subtract(7, 'days').startOf('day').toISOString(),
                    end: today.clone().startOf('day').endOf('day').toISOString(),
                }
            },
        },
        {
            id: "last30days",
            text: this.i18n.instant('REPORTING.ITEMS.FIELDS.VALUES.LAST_30_DAYS'),
            getDateRange: () => {
                const today = moment.utc().tz(moment.defaultZone.name);
                return {
                    start: today.clone().startOf('day').subtract(30, 'days').startOf('day').toISOString(),
                    end: today.clone().startOf('day').endOf('day').toISOString(),
                }
            },
        },
        {
            id: "thisMonth",
            text: this.i18n.instant('REPORTING.ITEMS.FIELDS.VALUES.THIS_MONTH'),
            getDateRange: () => {
                const today = moment.utc().tz(moment.defaultZone.name);
                return {
                    start: today.clone().startOf('month').toISOString(),
                    end: today.clone().endOf('day').toISOString(),
                }
            },
        },
    ]

    constructor(
        private i18n: TranslateService
    ) {
    }

    ngOnInit(): void { }

    handleChange(event: DateRangeChangeEvent) {
        this.onDateSelection.emit({
            start: event.start,
            end: event.end
        });
    }

}
