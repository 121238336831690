<kui-card>
    <kui-card-body>
        <div *ngFor="let layer of data.baseLayers; let l = last;"
            class="d-flex align-items-center"
            [class.mb-2]="!l">
            <input [id]="layer.title"
                [checked]="layer.visible"
                type="radio"
                name="baseLayers"
                class="mr-2"
                (click)="clickLayer($event, layer)">
            <label [for]="layer.title"
                class="mb-0">{{ layer.title }}</label>
        </div>
    </kui-card-body>
    <kui-card-body>
        <div *ngFor="let overlay of data.overlays; let l = last;"
            class="d-flex align-items-center"
            [class.mb-2]="!l">
            <input [id]="overlay.title"
                [checked]="overlay.visible"
                type="checkbox"
                class="mr-2"
                (click)="clickLayer($event, overlay)">
            <label [for]="overlay.title"
                class="mb-0">{{ overlay.title }}</label>
        </div>
    </kui-card-body>
    <kui-card-body *ngIf="data.supportsMarkerClustering">
        <div class="d-flex align-items-center">
            <input id="marker-clustering"
                [checked]="clustering"
                type="checkbox"
                name="marker-clustering"
                class="mr-2"
                (click)="toggleClustering()">
            <label for="marker-clustering"
                class="mb-0">{{ 'LEAFLET.MARKER_CLUSTERS' | translate }}</label>
        </div>
    </kui-card-body>
</kui-card>