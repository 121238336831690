<div class="list-input overflow-auto"
    [class.border-danger]="error || (field.required && touched && items.length === 0)">

    <div *ngFor="let item of items"
        class="list-item p-2 d-flex bg-contrast-hover">

        <kui-icon *ngIf="item.icon"
            class="p-2 pr-3"
            [ngStyle]="{ color: item.color }"
            [name]="item.icon"
            size="sm"></kui-icon>

        <div
            class="flex-1 d-flex flex-column justify-content-center"
            (click)="onItemClick(item)">
            <div>
                {{ item.name }}
            </div>
            <div *ngIf="item.description"
                class="description muted">
                {{ item.description }}
            </div>
        </div>
        <div>
            <kui-icon kui-action
                name="trash-alt"
                class="p-2 muted-more"
                size="sm"
                (click)="deleteItem(item)"></kui-icon>
        </div>
    </div>


</div>

<div class="d-flex">

    <div class="flex-1 invalid-feedback d-block">
        <div *ngIf="field.required && touched && items.length === 0">{{ 'FORMS.SHARED.REQUIRED' | translate:{ field: field.title } }}</div>
        <div *ngIf="error">{{ error }}</div>
    </div>

    <div *ngIf="field.actions"
        class="pt-2 pl-2">

        <span *ngFor="let action of field.actions">
            <button *ngIf="!action.dropdownValues && action.name"
                kui-button
                color="secondary"
                (click)="onActionClick(action)">{{ action.name }}</button>
            <kui-dropdown *ngIf="!!action.dropdownValues && action.name"
                #dropdown
                [style]="'secondary'"
                [customContent]="dropdownContent"
                [customContentContext]="{ component: dropdown }">
                {{ action.name }}
            </kui-dropdown>
            <ng-template #dropdownContent
                let-component="component">
                <div style="min-width: 200px">
                    <kui-tree-select [nodes]="action.dropdownValues"
                        (selectedNodeChanged)="onDropdownClick(component, action, $event)"></kui-tree-select>
                </div>
            </ng-template>

        </span>
    </div>
</div>