import { FeedResult, FeedHistoryResult, LinkedAsset } from './../feed.model';

import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { AuthService, MeasurementUnitsService } from 'app/services';
import { TripFeedItem } from '../feed.model';
import { FeedService, FeedOriginType } from '../feed.service';
import { TranslateService } from '@ngx-translate/core';
import { FeedsGetRecentTripFeedGQL, TripResponse } from 'app/shared/graphql';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ASSET_ICON_INDEX } from 'app/shared/model';
import * as Bluebird from 'bluebird';

@Injectable()
export class TripFeedService extends FeedService<TripFeedItem> {
    private linkedAssetsCache: { [id: string]: LinkedAsset } = {};

    constructor(app: AppService, public auth: AuthService, private units: MeasurementUnitsService, private i18n: TranslateService) {
        super(app, auth);
    }

    getFeedSubscription(clientId: string, originType: FeedOriginType, originId: string, limit: number): Observable<FeedResult<TripFeedItem>> {
        return new FeedsGetRecentTripFeedGQL(this.app.api.gql).subscribe({
            client: clientId,
            asset: originType === 'asset' ? originId : undefined,
            limit: limit,
        }).pipe(
            switchMap(async result => {
                const items = result.data.getRecentTripFeed;
                await this.addIconToLinkedAssets(items as TripResponse[]);
                return {
                    count: items.length,
                    sequence: [
                        !!items.length ? Math.min(...items.map(item => this.dateToSequence(item.dateStart))) : 0,
                        !!items.length ? Math.max(...items.map(item => this.dateToSequence(item.dateStart))) : 0,
                    ],
                    items: items.map(item => ({
                        id: item.id,
                        dateStart: item.dateStart,
                        dateEnd: item.dateEnd,
                        ratingScore: item.rating && item.rating.score,
                        distance: item.stats && item.stats.distance,
                        locationStart: item.start && item.start.address,
                        locationEnd: item.end && item.end.address,
                        type: item.tripType as any,
                        linkedAssets: item.linkedAssets,
                        section: {
                            title: this.units.format(item.dateEnd, 'date', 'long').format,
                            date: item.dateEnd,
                        },
                        date: item.dateEnd,
                    })),
                };
            }));
    }

    getFeed(clientId: string, originType: FeedOriginType, originId: string, sequence: number, direction: 'forward' | 'backward', limit: number): Promise<FeedResult<TripFeedItem>> {
        return this.app.api.data.getTripFeed(clientId, sequence, direction, limit, originType === 'asset' ? originId : null)
            .then(async result => {
                await this.addIconToLinkedAssets(result.items);
                return {
                    count: result.count,
                    sequence: [
                        result.sequence,
                        result.count !== 0 ? Math.max(...result.items.map(item => this.dateToSequence(item.dateStart))) : 0,
                    ],
                    items: result.items.map(item => ({
                        id: item.id,
                        dateStart: item.dateStart,
                        dateEnd: item.dateEnd,
                        ratingScore: item.rating && item.rating.score,
                        distance: item.stats && item.stats.distance,
                        locationStart: item.start && item.start.address,
                        locationEnd: item.end && item.end.address,
                        type: item.tripType,
                        linkedAssets: item.linkedAssets,
                        section: {
                            title: this.units.format(item.dateEnd, 'date', 'long').format,
                            date: item.dateEnd,
                        },
                        date: item.dateEnd,
                    })),
                };
            });
    }

    protected getFeedHistory(_clientId: string, _originType: FeedOriginType, originId: string, start: string, end: string, limit: number): Promise<FeedHistoryResult<TripFeedItem>> {
        return this.app.api.data.getTripHistory(originId, start, end, undefined, limit)
            .then(async result => {
                await this.addIconToLinkedAssets(result.items);
                return {
                    count: result.count,
                    start: result.start,
                    end: result.end,
                    limit: result.limit,
                    items: result.items.map(item => ({
                        id: item.id,
                        dateStart: item.dateStart,
                        dateEnd: item.dateEnd,
                        ratingScore: item.rating && item.rating.score,
                        distance: item.stats && item.stats.distance,
                        locationStart: (item.start && item.start.address) || this.i18n.instant('SHARED.UNKNOWN_LOCATION'),
                        locationEnd: (item.end && item.end.address) || this.i18n.instant('SHARED.UNKNOWN_LOCATION'),
                        type: item.tripType,
                        linkedAssets: item.linkedAssets,
                        section: {
                            title: this.units.format(item.dateEnd, 'date', 'long').format,
                            date: item.dateEnd,
                        },
                        date: item.dateEnd,
                    })),
                };
            });
    }

    async addIconToLinkedAssets(items: TripResponse[]) {
        await Bluebird.map(items, async event => {
            event.linkedAssets = await Bluebird.map(event.linkedAssets, async asset => {
                return this.linkedAssetsCache[asset.id] || await this.app.api.entities.getAsset(asset.id).then(x => {
                    const item = {
                        name: x.name,
                        id: x.id,
                        type: x.assetType.name,
                        icon: ASSET_ICON_INDEX[(x.assetType.name || 'default').toLowerCase()],
                    };
                    this.linkedAssetsCache[asset.id] = item;
                    return item;
                }).catch(err => {
                    if (err.name === 'ForbiddenError') { // this must be a shared asset that we do not have access to, so fake it as best we can and assume it's a driver
                        const item = {
                            name: asset.name,
                            id: asset.id,
                            type: '',
                            icon: 'link',
                        };
                        this.linkedAssetsCache[asset.id] = item;
                        return item;
                    }
                    console.error(err);
                });
            }, { concurrency: 1 });
        }, { concurrency: 1 });
    }
}
