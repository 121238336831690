<key-date-select *ngIf="showDateSelect" [date]="dateRange && dateRange.end"
    (onDateSelection)="onDateSelect($event.date)"
    [currentString]="'VIDEOS.LIST.SAVED_VIDEOS' | translate">
</key-date-select>

<div *ngIf="videoListing.length === 0 && listLoaded">
    <kui-alert type="info"
        class="key-alert-wrapper">
        <kui-icon name="lightbulb"
            size="sm"></kui-icon>
        <span>{{ noVideosMessage ? (noVideosMessage | translate): 'VIDEOS.LIST.NO_VIDEOS' | translate }}</span>
    </kui-alert>
    <div class="custom-message-container" *ngIf="!noVideosMessage">
        <div class="d-flex">
            <span>{{ 'VIDEOS.LIST.NO_VIDEOS_DESC1' | translate }}</span>
        </div>

        <div class="d-flex">
            <span>{{ 'VIDEOS.LIST.NO_VIDEOS_DESC2' | translate }}</span>
        </div>
        <button class="mt-3 ml-auto mr-auto"
            kui-button
            color="primary"
            (click)="requestVideo.emit()">
            <kui-icon
                class="mr-2"
                name="video"></kui-icon><span>{{ 'DIALOG.VIDEO' | translate }}</span>
        </button>
    </div>
</div>

<div key-content-container
    class="d-flex"
    [scroll]="true"
    #contentContainer>

    <div class="loading-overlay d-flex flex-stretch justify-content-center align-items-center"
        *ngIf="!listLoaded && !errorMessage">
        <kui-loader>
        </kui-loader>
    </div>

    <kui-alert-panel [message]="errorMessage"></kui-alert-panel>

    <div class="flex-1"
        *ngIf="!errorMessage && listLoaded">
        <key-video-list-item *ngFor="let video of videoListing"
            [class.selected]="video.id === selectedId"
            [data]="video"
            [showFilterByValue]="showFilterByValue"
            (selected)="itemSelected.emit($event)">
        </key-video-list-item>
    </div>
</div>
<kui-content-header *ngIf="count"
    [custom]="true"
    [sub]="true"
    class="w-100">

    <kui-paginate *ngIf="pageCount > 1"
        class="ml-3"
        [page]="page"
        [total]="count"
        [perPage]="limit"
        [collapse]="true"
        (pageChanged)="onPageChanged($event)"></kui-paginate>

    <div class="ml-auto pr-3">
        {{ count === 1 ? ('STATUS.LIST.ONE_ITEM' | translate) : ('STATUS.LIST.TOTAL_ITEMS' | translate: { total: count }) }}
    </div>

</kui-content-header>