import { OnDestroy, Injectable } from '@angular/core';
import { ModalService } from '../../modal';
import { TranslateService } from '@ngx-translate/core';
import { ComboValueHandlers, FormBuilderDefinition, FormBuilderField } from '../../form-builder';
import { AppService } from 'app/app.service';
import { ReportConfig, ReportDefinitionListResponse, ReportDefinitionResponse, CompletedReportResponse, EventActorFilter } from '@key-telematics/fleet-api-client';
import { ReportingService } from '../../reports';
import { QueuedReportResponse } from 'app/shared/graphql';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as Papa from 'papaparse'; // csv parser
import { Router } from '@angular/router';
import { MapZone } from '../../map/map.component';
import { cloneDeep, isEqual, round } from 'lodash';
import { AssetFilterService, AssetGroupingService, MeasurementUnitsService } from 'app/services';
import { Filter, isQuery, Query } from 'app/services/assets/asset-filter.service';


export interface MapSearchOptions {
    searchType?: string;
    searchName?: string;
    parameters?: any;
}

export interface MapSearchFocus {
    lat: number;
    lon: number;
    radius?: number;
}

export interface MapSearchConfig {
    id: string;
    name: string;
    form: FormBuilderDefinition;
    reportConfigMatches: (config: ReportConfig) => boolean;
    localizeToUser: (form: FormBuilderDefinition, parameters: any) => void;
    localizeToServer: (parameters: any) => void;
}

@Injectable()
export class MapSearchService implements OnDestroy {

    reportDefinitions: Promise<ReportDefinitionListResponse>;

    REPORT_TYPES = [
        { source_name: 'Zone Report', id: undefined, styleId: 'scan_listing', key: 'time', value: this.i18n.instant('MAPSEARCH.OUTPUTS.TIME') },
        { source_name: 'Trip Listing Report', id: undefined, styleId: 'listing', key: 'trips', value: this.i18n.instant('MAPSEARCH.OUTPUTS.TRIPS') },
    ];

    MAPSEARCH_TYPES: MapSearchConfig[] = [
        {
            id: 'zone',
            name: this.i18n.instant('MAPSEARCH.FORMS.ZONE_SEARCH.TITLE'),
            form: {
                groups: [{
                    name: this.i18n.instant('MAPSEARCH.FORMS.ZONE_SEARCH.TITLE'),
                    description: this.i18n.instant('MAPSEARCH.FORMS.ZONE_SEARCH.DESCRIPTION'),
                    fields: [
                        { id: 'output', type: 'combo', title: this.i18n.instant('MAPSEARCH.FORMS.ZONE_SEARCH.OUTPUT'), required: true, values: this.REPORT_TYPES, ...ComboValueHandlers },
                        {
                            id: 'zone', type: 'combo', title: this.i18n.instant('MAPSEARCH.FORMS.ZONE_SEARCH.ZONE'), required: true,
                            lookupFunc: () => {
                                return this.app.api.entities.listZones(this.app.client.id, 0, 1000, 'name:asc', 'state=active&zoneType!=route').then(result => {
                                    return result.items.map(item => ({ key: item.id, value: item.name })).sort((a, b) => a.value.localeCompare(b.value));
                                });
                            },
                            getText: (_field, values) => values.zoneName || values.zoneId,
                        },
                        this.getDateRangeField(),
                        { id: 'assetSelection', type: 'assetfilter', title: this.i18n.instant('MAPSEARCH.FORMS.ZONE_SEARCH.ASSETS'), required: true, options: { excludeCategories: true } },
                    ],
                }],
            },
            reportConfigMatches: (config: ReportConfig) => {
                return !!(config.parameters.zone);
            },
            localizeToUser: (_form: FormBuilderDefinition, _parameters: any) => {
                // no localization required
            },
            localizeToServer: (_parameters: any) => {
                // no localization required
            },
        },
        {
            id: 'point',
            name: this.i18n.instant('MAPSEARCH.FORMS.POINT_SEARCH.TITLE'),
            form: {
                groups: [{
                    name: this.i18n.instant('MAPSEARCH.FORMS.POINT_SEARCH.TITLE'),
                    description: this.i18n.instant('MAPSEARCH.FORMS.POINT_SEARCH.DESCRIPTION'),
                    fields: [
                        { id: 'output', type: 'combo', title: this.i18n.instant('MAPSEARCH.FORMS.ZONE_SEARCH.OUTPUT'), required: true, values: this.REPORT_TYPES, ...ComboValueHandlers },
                        { id: 'lat', type: 'text', title: this.i18n.instant('MAPSEARCH.FORMS.POINT_SEARCH.LAT'), required: true, onChange: null },
                        { id: 'lon', type: 'text', title: this.i18n.instant('MAPSEARCH.FORMS.POINT_SEARCH.LON'), required: true, onChange: null },
                        { id: 'radius', type: 'number', title: this.i18n.instant('MAPSEARCH.FORMS.POINT_SEARCH.RADIUS'), required: true, unit: 'm', min: 0.01, max: 1, options: { step: 0.1 }, onChange: null },
                        this.getDateRangeField(),
                        { id: 'assetSelection', type: 'assetfilter', title: this.i18n.instant('MAPSEARCH.FORMS.POINT_SEARCH.ASSETS'), required: true, options: { excludeCategories: true } },
                    ],
                }],
            },
            reportConfigMatches: (config: ReportConfig) => {
                return !!(config.parameters.lat);
            },
            localizeToUser: (form: FormBuilderDefinition, parameters: any) => {
                if (form) {
                    const radius = form.groups[0].fields.find(x => x.id === 'radius');
                    radius.unit = this.measurement.getUnitConfig('distance');
                }
                if (parameters?.radius) {
                    parameters.radius = this.measurement.fromBackend('distance', parameters.radius, 2);
                }
            },
            localizeToServer: (parameters: any) => {
                if (parameters?.radius) {
                    parameters.radius = this.measurement.toBackend('distance', parameters.radius, 2);
                }
            },
        },
    ];


    private selectedReportSubject = new BehaviorSubject<string>(null);
    selectedReport$ = this.selectedReportSubject.asObservable();
    selectedReport: string;

    private searchFocusSubject = new BehaviorSubject<MapSearchFocus>(null);
    searchFocus$ = this.searchFocusSubject.asObservable();
    searchFocus: MapSearchFocus;

    constructor(
        private app: AppService,
        public http: HttpClient,
        public router: Router,
        private modal: ModalService,
        private i18n: TranslateService,
        private measurement: MeasurementUnitsService,
        private reportingService: ReportingService,
        private assetFilterService: AssetFilterService,
        private assetGroupingService: AssetGroupingService
    ) {

    }

    ngOnDestroy() {

    }

    setSelectedReport(reportId: string) {
        if (this.selectedReportSubject.value !== reportId) {
            this.selectedReport = reportId;
            this.selectedReportSubject.next(reportId);
        }
    }

    setSearchFocus(searchFocus: MapSearchFocus) {
        if (!isEqual(searchFocus, this.searchFocus)) {
            this.searchFocus = searchFocus;
            this.searchFocusSubject.next(this.searchFocus);
        }
    }

    async getReportDefinitions(): Promise<ReportDefinitionResponse[]> {
        if (!this.reportDefinitions) {
            this.reportDefinitions = this.app.api.entities.listReportDefinitions(this.app.client.id).then(result => {
                // on first load, look up the id's of the reports we support
                result.items.forEach(item => {
                    const mapSearch = this.REPORT_TYPES.find(x => x.source_name === item.name);
                    if (mapSearch) {
                        mapSearch.id = item.id + '.' + mapSearch.styleId;
                    }
                });
                return result;
            });
        }
        return await this.reportDefinitions.then(result => result.items);
    }

    async getReportDefinition(id: string): Promise<ReportDefinitionResponse> {
        return (await this.getReportDefinitions()).find(x => x.id === id);
    }

    getMapSearchFromReportConfig(config: ReportConfig) {
        return cloneDeep(this.MAPSEARCH_TYPES.find(x => x.reportConfigMatches(config)));
    }

    reportIsMapSearch(report: CompletedReportResponse): boolean {
        return (report.outputFormat === 'raw' && !!this.REPORT_TYPES.find(x => x.id === report.config.definitionId + '.' + report.config.styleId));
    }


    getDateRangeField(): FormBuilderField { // TODO: translate!
        return {
            'id': 'dateRange', 'title': 'Date Range', 'type': 'combo', getText: (f, values) => {
                const key: string = values[f.id];
                return this.i18n.instant(`REPORTING.ITEMS.FIELDS.VALUES.${key.toUpperCase()}`);
            },
            'values': [
                { 'key': 'today', 'value': 'Today' },
                { 'key': 'yesterday', 'value': 'Yesterday' },
                { 'key': 'lastweek', 'value': 'Last 7 Days' },
                {
                    'key': 'custom', 'value': 'Custom',
                    'fields': [
                        { 'id': 'dateStart', 'title': 'From Date', 'type': 'datetime', 'width': 150, 'required': true },
                        { 'id': 'dateEnd', 'title': 'To Date', 'type': 'datetime', 'width': 150, 'required': true },
                    ],
                },
            ],
        };
    }

    async showZoneSearchOptionsDialog(options: MapSearchOptions): Promise<MapSearchOptions> {

        const mapsearch = this.MAPSEARCH_TYPES.find(x => x.id === 'zone');

        await this.getReportDefinitions(); // make sure our definitions are loaded

        const onChange = async (_field: FormBuilderField, values) => {
            if (_field.id === 'zone') {
                const zoneName = _field.values.find(x => x.key === values.zone).value || values.zoneName;
                values.zoneName = zoneName;
                searchName = zoneName;
            }
        };

        let searchName = options.parameters?.zoneName || mapsearch.name;

        const form = cloneDeep(mapsearch.form);

        form.groups[0].fields.forEach(field => field.onChange = onChange.bind(this));

        const result = await this.modal.form(form, {
            ...options.parameters || {},
            output: 'time',
            targetType: 'zone',
            zoneFilterType: 'zone',
            mode: 'fast',
        });
        if (result) {
            const reportType = this.REPORT_TYPES.find(x => x.key === result.output);
            return {
                searchType: reportType.id,
                searchName: this.i18n.instant('MAPSEARCH.REPORT_TITLE', { action: reportType.value, name: searchName }),
                parameters: result,
            };
        }
        return null;
    }

    async showPointSearchOptionsDialog(options: MapSearchOptions): Promise<MapSearchOptions> {

        await this.getReportDefinitions(); // make sure our definitions are loaded

        const mapsearch = this.MAPSEARCH_TYPES.find(x => x.id === 'point');

        const onChange = async (_field, values) => {
            const radius = this.measurement.toBackend('distance', Number(values.radius), 2) as number;
            this.setSearchFocus({ lat: Number(values.lat), lon: Number(values.lon), radius: radius });
        };

        const form = cloneDeep(mapsearch.form);

        form.groups[0].fields.forEach(field => field.onChange = onChange.bind(this));

        const params = {
            ...options.parameters || {},
            output: 'time',
            targetType: 'point',
            zoneFilterType: 'point',
            mode: 'fast',
            radius: (options.parameters.radius || 0.1), // convert to meters
        };
        mapsearch.localizeToUser(form, params);
        const result = await this.modal.form(form, params);
        if (result) {
            mapsearch.localizeToServer(result);
            const reportType = this.REPORT_TYPES.find(x => x.key === result.output);
            return {
                searchType: reportType.id,
                searchName: this.i18n.instant('MAPSEARCH.REPORT_TITLE', { action: reportType.value, name: options.parameters?.address || mapsearch.name }),
                parameters: result,
            };
        }
        return null;

    }

    async queueMapSearch(options: MapSearchOptions): Promise<QueuedReportResponse> {
        const [id, styleId] = options.searchType.split('.');

        const def = await this.getReportDefinition(id);
        if (def) {
            return await this.reportingService.queueReport({
                clientId: this.app.client.id,
                name: this.i18n.instant(`MAPSEARCH.MAPSEARCH`) + ': ' + options.searchName,
                title: this.i18n.instant(`MAPSEARCH.MAPSEARCH`) + ': ' + options.searchName,
                source: 'dataset',
                outputFormat: 'raw',
                config: {
                    definitionId: def.id,
                    language: 'en-us',
                    parameters: options.parameters,
                    styleId: styleId,
                },
            });
        } else {
            throw new Error('Unable to find the map search report definition');
        }
    }



    async doMapSearch(options: MapSearchOptions): Promise<boolean> {
        try {
            if (options) {
                const report = await this.queueMapSearch(options);
                this.reportingService.adjustPollingInterval(); // forces an update of the search list
                this.router.navigate(['mapsearch', 'now', 'viewer', report.id]);
                return true;
            } else {
                return false;
            }
        } catch (err) {
            this.modal.error(err.message);
        }
    }

    async openZoneSearch(zone: MapZone): Promise<boolean> {
        const options = await this.showZoneSearchOptionsDialog({
            parameters: {
                dateRange: 'today',
                zone: zone.id,
                zoneName: zone.name,
                assetSelection: await this.getCurrentAssetSelection(),
            },
        });
        return this.doMapSearch(options);
    }

    async openPointSearch(lat: number, lon: number, address?: string): Promise<boolean> {
        const radius = 0.1;
        this.setSearchFocus({ lat, lon, radius });
        const options = await this.showPointSearchOptionsDialog({
            parameters: {
                address: address,
                dateRange: 'today',
                lat: round(lat, 6),
                lon: round(lon, 6),
                radius: radius,
                assetSelection: await this.getCurrentAssetSelection(),
            },
        });
        if (!options) {
            this.setSearchFocus(null);
        }
        return this.doMapSearch(options);
    }

    getReport(id: string): Promise<CompletedReportResponse> {
        return this.reportingService.getCompletedReport(id, false);
    }


    downloadReport(id: string): Promise<any[]> {
        const fileName = `${id}.csv`;
        const url = `${this.app.env.apiEndpoint}/reports/history/${id}/${fileName}`;
        return this.http.get(url, {
            headers: { 'x-access-token': this.app.api.accessToken },
            responseType: 'blob',
        }).toPromise().then(async res => {
            const csv = await res.text();
            try {
                const papa = Papa.parse(csv, {
                    skipEmptyLines: true,
                    quoteChar: '"',
                });
                if (papa.errors && papa.errors.length > 0) {
                    throw new Error(`${papa.errors[0].message} (line ${papa.errors[0].row + 1})`);
                }
                const result = [];
                if (papa.data.length > 1) {
                    const headers = papa.data[0] as string[];
                    // ignore the header
                    for (let i = 1; i < papa.data.length; i++) {
                        const obj = {};
                        (papa.data[i] as string[]).forEach((value, index) => {
                            obj[headers[index]] = value.replace('="', '').replace('"', '');
                        });
                        result.push(obj);
                    }
                }
                return result;
            } catch (err) {
                if (err.message.includes('Unable to auto-detect delimiting character')) { // this likely means the CSV file was empty
                    return [];
                }
                throw err;
            }
        });

    }


    async getCurrentAssetSelection(): Promise<EventActorFilter[]> {

        const filters = this.assetFilterService.filters;

        const assetTypes = await this.app.api.entities.listAssetTypes(this.app.client.id);

        const assetTypeIds = this.getFilterValues(filters['assettype']);
        if (assetTypeIds.length === 0) {
            // no filters specified, assume "any vehicle".
            assetTypeIds.push(assetTypes.items.find(x => x.tag === 'vehicle').id);
        }

        const actorTypes = assetTypeIds.map(id => {
            const type = assetTypes.items.find(x => x.id === id);
            const name = this.i18n.instant(`SHARED.ASSET_TYPES.${type.name.toUpperCase().replace(/ /g, '_')}`);
            return {
                id,
                type,
                name
            }
        })

        const costCentreIds = this.getFilterValues(filters['costcentre']);
        if (costCentreIds.length > 0) {
            const costCentres = await this.assetGroupingService.getCostCentres(this.app.client.id);
            const costCentreItems = costCentres.filter(centre => costCentreIds.includes(centre.id));

            // we only use the first actor type here, as it would end up with us having two actor filters with the same actor id.
            const type = actorTypes[0];
            const actorFilters: EventActorFilter[] = costCentreItems.map(item => {
                const desc = this.i18n.instant('FORMS.ASSETFILTER.GROUP_DESC.COST_CENTRE', { assetType: type.name, name: item.name });
                return {
                    actorId: item.id,
                    actorName: item.name,
                    actorSelectionType: 'accessGroup',
                    actorType: 'asset',
                    actorTypeId: type.id,
                    actorTypeName: type.name,
                    text: desc,
                };
            });

            return actorFilters;
        }

        const assetGroupIds = this.getFilterValues(filters['assetgroup']);
        if (assetGroupIds.length > 0) {

            const assetGroups = await this.assetGroupingService.getAssetGroups(this.app.client.id);
            const assetGroupsItems = assetGroups.filter(group => assetGroupIds.includes(group.id));

            // we only use the first actor type here, as it would end up with us having two actor filters with the same actor id.
            const type = actorTypes[0];
            const actorFilters: EventActorFilter[] = assetGroupsItems.map(item => {
                const desc = this.i18n.instant('FORMS.ASSETFILTER.GROUP_DESC.GROUP', { assetType: type.name, name: item.name });
                return {
                    actorId: item.id,
                    actorName: item.name,
                    actorSelectionType: 'group',
                    actorType: 'asset',
                    actorTypeId: type.id,
                    actorTypeName: type.name,
                    text: desc,
                };
            });

            return actorFilters;
        }

        // if there are no current filters defined, assume we're looking for "any vehicles"
        const type = actorTypes[0];

        return [{
            actorId: '00000000-0000-0000-0000-000000000000',
            actorSelectionType: 'any' as any,
            actorType: 'asset' as any,
            actorTypeId: type.id,
            actorTypeName: type.name,
            text: this.i18n.instant('FORMS.ASSETFILTER.GROUP_DESC.ANY', { assetType: type.name }),
        }];

    }

    private getFilterValues(filter: Query | Filter): string[] {
        let ids: string[] = [];
        if (filter) {
            ids = isQuery(filter) ? [filter.value] : filter.queries.map(query => query.value);
        }
        return ids.filter(id => id !== '');
    }


}
