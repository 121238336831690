import { Injectable, NgZone } from '@angular/core';
import { Observable, interval, BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import { CurrentClient, CurrentUser, CurrentTheme } from 'app/shared/model';
import { ApiService } from 'app/services/api/api.service';
import { LocalStore } from 'app/services/storage/storage.service';
import { HttpClient } from '@angular/common/http';
import { switchMap, filter, map } from 'rxjs/operators';
import { set, cloneDeep, merge } from 'lodash';
import { getThemeDictionary, isLegacyTheme, convertLegacyTheme } from 'app/shared/components/theme/theme-dictionary';
import { APP_FEATURE_FLAGS, AppFeatureDefaults } from './app.features';
import * as moment from 'moment-timezone';
import { ThemeEngine } from './shared/components/theme';
import { DomainInfo, PolicyEvaluator, UserPoliciesResponse } from '@key-telematics/fleet-api-client';
import { BackendFeatureDefaults, BACKEND_FEATURE_FLAGS } from './shared/components/admin/entities/client/backend.features';


export interface EnvironmentVariables {
    version: string;
    /** available endpoints */
    apiEndpoints: string[];
    /** selected api endpoint */
    apiEndpoint: string;
    storePrefix: string;
    /** The google analytics tracking ID */
    trackingId: string;
    sentryDsn: string;
}

/** The AppService is the root service that holds the state of the global application. */
@Injectable()
export class AppService {

    api: ApiService; // we attach the ApiService directly to the AppService, as everything that needs the AppService will also need access to the ApiService.

    appStore: LocalStore;
    private themeDictionary = getThemeDictionary();

    features = cloneDeep({ ...AppFeatureDefaults, ...BackendFeatureDefaults });

    /** Add development related feature flags here, instead of doing logic checks all over the code. This makes it easy to find the places 
     * where the flags are used and to remove them when the features is released and no longer needed.  */
    flags = {
        otpTestingEnabled: () => this.user.owner.type === 'system' || this.user.emailAddress.endsWith('keytelematics.com'),
    }

    constructor(
        zone: NgZone,
        private http: HttpClient
    ) {
        // populate our internal environment from the base environment variables, settings
        // particular to our actual deployed environment will be loaded in the loadConfiguration method
        this.env = {
            ...environment,
        } as any;

        this.appStore = this.getStore('app');
        this.api = new ApiService(zone, http);
        this.api.setEndpoint(null); // tests rely on endpoint being null initially

    }

    env: EnvironmentVariables;

    /** the vanity domain of the end user, or the AWS domain if not specified */
    domain: string;
    domainInfo: DomainInfo;

    /** the domain that was intially entered before angular did any redirect magic */
    entryUrl: string;

    private userSubject: BehaviorSubject<CurrentUser> = new BehaviorSubject(null);
    private clientSubject: BehaviorSubject<CurrentClient> = new BehaviorSubject(null);
    private themeSubject: BehaviorSubject<CurrentTheme> = new BehaviorSubject(this.themeDictionary.default);

    get user$(): Observable<CurrentUser> {
        return this.userSubject.asObservable();
    }

    // a helper to get current user only once without listening for updates
    get user(): CurrentUser {
        return this.userSubject.getValue();
    }

    private userPolicies: UserPoliciesResponse = null;

    async setUser(user: CurrentUser): Promise<void> {

        if (user) {
            if (user.timeZoneId) {
                /**
                 * Are we using "area" timezones
                 *
                 * Really, they are offsets on v1, but retrofitting them here.
                 * It should be noted that while V1 goes from -12 to +12, area
                 * timezones go from -14 to +12.
                 *
                 * @see https://en.wikipedia.org/wiki/Tz_database#Area
                 */
                if (user.timeZoneId.search(/GMT[+\-][0-9][0-9]?/i) > -1) {
                    // moment timezone signs are inverted, see: https://momentjs.com/timezone/docs/#/zone-object/offset/
                    let t = user.timeZoneId.toUpperCase();
                    t = t.indexOf('+') >= 0 ? t.replace('+', '-') : t.replace('-', '+');
                    moment.tz.setDefault('Etc/' + t);
                } else {
                    moment.tz.setDefault(user.timeZoneId);
                }
            } else {
                moment.tz.setDefault(moment.tz.guess());
            }
            try {
                this.userPolicies = (this.api.endpoint !== null && await this.api.accounts.getUserPolicies(user.id)) || null;
            } catch (err) {
                console.error(err);
                this.userPolicies = null;
            }
        } else {
            this.userPolicies = null;
        }

        this.userSubject.next(user);

    }

    get isSystemUser(): boolean {
        return this.user && this.user.owner && this.user.owner.type === 'system';
    }

    get isStaging(): boolean {
        return this.domain && ['127.0.0.1', 'localhost', 'fleet.stage.kt1.io'].includes(this.domain);
    }

    get client$(): Observable<CurrentClient> {
        return this.clientSubject.asObservable();
    }
    // a helper to get current client only once without listening for updates
    get client(): CurrentClient {
        return this.clientSubject.getValue();
    }
    setClient(client: CurrentClient): void {
        if (client !== null) { // don't save null to local-storage, or we won't be able to reload the last used client
            this.appStore.set('client', client);
        }
        this.features = this.getFeaturesForClient(client);
        this.clientSubject.next(client);
    }

    get theme$(): Observable<CurrentTheme> {
        return this.themeSubject.asObservable();
    }
    get theme(): CurrentTheme {
        return this.themeSubject.getValue();
    }
    setTheme(theme: CurrentTheme): CurrentTheme {
        const t = cloneDeep(theme);
        if (isLegacyTheme(t)) { // need to do this here before it gets emitted
            const themeDictionary = getThemeDictionary();
            const newTheme = merge(cloneDeep(themeDictionary[theme.theme]), theme);
            t.settings = convertLegacyTheme(newTheme);
        }
        const engine = new ThemeEngine();
        t.variables = { ...t.variables, ...engine.settingsToVariables(t.settings) };
        this.themeSubject.next(t);
        this.appStore.set('theme', theme);
        return t;
    }

    /** poll the environment file every hour to see if the app has changed within the user session */
    get version$(): Observable<string> {
        return interval(60 * 60 * 1000).pipe(
            switchMap(() => this.http.get(`assets/env.json?timestamp=${new Date().getTime()}`)),
            filter((res: EnvironmentVariables) => this.env.version !== res.version), // only emit next if the version is outdated
            map((res: EnvironmentVariables) => res.version)
        );
    }


    setApiEndpoint(endpoint: string) {
        this.env.apiEndpoint = endpoint;
        this.api.setEndpoint(this.env.apiEndpoint);
        this.appStore.set('apiEndpoint', this.env.apiEndpoint);
    }

    /** This function is called by the APP_INITIALIZER hook before the application is initialized */
    loadConfiguration(): Promise<void> {
        // add timestamp to make sure we always load a non cached version on app start
        return this.http.get(`assets/env.json?timestamp=${new Date().getTime()}`).toPromise()
            .then((res: EnvironmentVariables) => {
                this.env = { ...this.env, ...res };

                // try and load the endpoint the user used last, otherwise use the first one
                const lastEndpoint = this.appStore.get<string>('apiEndpoint');
                if (lastEndpoint && this.env.apiEndpoints.indexOf(lastEndpoint) > -1) {
                    this.setApiEndpoint(lastEndpoint);
                } else {
                    this.setApiEndpoint(this.env.apiEndpoints[0]);
                }
            })
            .catch(() => {
                console.error('Configuration file "env.json" could not be read.');
            });
    }

    /** make any requests to the api we may need and load stored state here */
    async initialiseState(): Promise<void> {
    }

    /** This function is called by the APP_INITIALIZER hook before the application is initialized */
    getDomainTheme(domain: string): Promise<CurrentTheme> {
        this.domain = domain;
        if (!this.env.apiEndpoint) { // don't make a request if no API is configured
            return Promise.resolve(this.themeDictionary.default);
        }
        return this.api.entities.getThemeForDomain(domain).then(result => {
            const ownerId = (result.domain.owner && result.domain.owner.id) || '00000000-0000-0000-0000-000000000000';
            this.domainInfo = result.domain;
            return this.setTheme({
                ...result.theme,
                logoPath: `${this.env.apiEndpoint}/accounts/clients/${ownerId}/logo`,
            });
        }).catch(err => {
            // don't bother the user with any errors here, simply log the error
            this.logError(err);
            // and fall back to the default theme
            return this.setTheme({
                id: 'default',
                name: 'Default',
                theme: 'default',
                settings: {},
                owner: null,
                entity: null,
            });
        });
    }

    logError(err: any) {
        // TODO: push this though the global error handling mechanism
        console.error(err);
    }

    public getStore(name: string): LocalStore {
        return new LocalStore(name);
    }

    public resetStorage(): void {
        localStorage.clear();
    }

    getFeaturesForClient(client: { flags?: any }): typeof AppFeatureDefaults & typeof BackendFeatureDefaults {
        if (client) {
            const result = cloneDeep({ ...AppFeatureDefaults, ...BackendFeatureDefaults });
            const flags = client.flags || {};
            // overwrite our default app feature values with the ones received from the client
            Object.keys(flags[APP_FEATURE_FLAGS] || {}).forEach(id => {
                set(result, id.replace(/-/g, '.'), flags[APP_FEATURE_FLAGS][id]);
            });
            // overwrite our default backend feature values with the ones received from the client
            Object.keys(flags[BACKEND_FEATURE_FLAGS] || {}).forEach(id => {
                set(result, id.replace(/-/g, '.'), flags[BACKEND_FEATURE_FLAGS][id]);
            });
            return result;
        } else {
            return cloneDeep({ ...AppFeatureDefaults, ...BackendFeatureDefaults });
        }
    }

    apiMethodAllowed(service: string, method: string): boolean {
        if (this.userPolicies) {
            return PolicyEvaluator.methodAllowed(this.userPolicies?.policies || [], service, method);
        }
        return true;
    }

}


