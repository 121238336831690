import { Component, Input, OnChanges, SimpleChanges, ChangeDetectorRef, QueryList, ViewChildren } from '@angular/core';
import { FormBuilderDefinition, FormBuilderField, FormBuilderKeyValue, KeyFormBuilderComponent } from '../../form-builder';
import { ReportDefinitionColumnOptions, CompletedReportResponse, QueueReportRequest } from '@key-telematics/fleet-api-client';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { ReportDefinition, CachedReportParameters } from '../services/reporting.service';
import { MatchMediaService } from 'app/services';
import { MeasurementUnitsService } from 'app/services/measurement-units/measurement-units.service';
import { BaseComponent } from '../../base/base.component';
import { MeasurementUnits, MeasurementUnitType } from 'app/services/measurement-units/measurement-units.service';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { AppService } from 'app/app.service';

@Component({
    selector: 'key-report-parameter-form',
    templateUrl: './report-parameter-form.component.html',
})
export class ReportParameterFormComponent extends BaseComponent implements OnChanges {

    private readonly DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';

    @Input() clientId: string;
    @Input() reportDefinition: ReportDefinition;
    @Input() report: CompletedReportResponse;
    @Input() parameters: CachedReportParameters;
    @Input() isSheduledReport: boolean;

    @Input() showHiddenFields = false;

    @ViewChildren(KeyFormBuilderComponent) formBuilders: QueryList<KeyFormBuilderComponent>;

    reportForm: FormBuilderDefinition;
    reportValues = {};

    parametersForm: FormBuilderDefinition;
    parameterValues = {};

    layoutForm: FormBuilderDefinition;
    layoutFormValues = {};

    hasLayout = false;
    isMobile = false;

    columns: ReportDefinitionColumnOptions[];

    constructor(
        public app: AppService,
        private i18n: TranslateService,
        private matchMedia: MatchMediaService,
        private ref: ChangeDetectorRef,
        public units: MeasurementUnitsService
    ) {

        super();

        this.matchMedia.isMobile
            .pipe(takeUntil(this.destroyed))
            .subscribe(isMobile => {
                this.isMobile = isMobile;
                this.ref.markForCheck();
            });

    }

    validate() {
        return this.formBuilders.reduce((result, form) => result + (form.validate() ? 0 : 1), 0) === 0;
    }

    getValues(): QueueReportRequest {
        return {
            clientId: this.clientId,
            name: this.reportDefinition.name,
            title: this.reportValues['title'] || this.reportDefinition.name,
            source: this.reportDefinition.type,
            outputFormat: this.reportValues['outputFormat'] || 'pdf',
            config: {
                definitionId: this.reportDefinition.template ? this.reportDefinition.template.config.definitionId : this.reportDefinition.def.id,
                language: 'en-us',
                parameters: this.getParameters(),
                styleId: this.reportDefinition.template ? this.reportDefinition.template.config.styleId : this.reportDefinition.def.style,
            },
        };
    }

    getParameters(): any {
        const params = { ...this.parameterValues };

        const transformFields = (fields: FormBuilderField[]) => {
            fields.forEach(field => {
                // vendor and distributor searchselect filters have a somewhat different format than required by the report servers. transform them here
                if (field.type === 'searchselect') {
                    const value = this.parameterValues[field.id];
                    if (value && Array.isArray(value)) {
                        params[field.id] = value.filter(x => x).map(x => {
                            return x.filter ? x.filter : x;
                        });
                    }
                }
                // ensure we return an array for checklist values
                if (field.type === 'checklist') {
                    const value = this.parameterValues[field.id];
                    if (value && !Array.isArray(value)) {
                        params[field.id] = [value];
                    }
                }
                // see if there are any sub fields and recurse
                (field.values || []).forEach(value => {
                    if (value.fields) {
                        transformFields(value.fields);
                    }
                });
            });
        };

        this.parametersForm.groups.forEach(group => {
            transformFields(group.fields);
        });

        return {
            ...params,
            layout: { ...this.layoutFormValues, columns: this.columns },
        };
    }


    ngOnChanges(changes: SimpleChanges) {

        if (changes.reportDefinition && this.reportDefinition) {
            const def = cloneDeep(this.reportDefinition);

            const cached = def.template ? null : this.parameters;

            const params = this.report
                ? this.report.config.parameters
                : ((cached && cached.params) || (def.template && def.template.config.parameters) || {});

            const layout = this.report
                ? this.report.config.parameters['layout']
                : ((cached && cached.layout) || (def.template && def.template.config.parameters['layout']) || {});

            // set up some sane defaults for the dateStart and dateEnd value which is needed to seed the date/time controls
            if (def.parameters && def.parameters.groups.length > 0) {
                const dateRange = def.parameters.groups[0].fields.find(x => x.id === 'dateType' || x.id === 'dateRange');
                if (dateRange) {

                    // Any previously saved date will be in the users timezone, parse it accordinly. 
                    const startDate = params.dateStart ? moment(params.dateStart, this.DATE_TIME_FORMAT) : moment().startOf('day')
                    const endDate = params.dateEnd ? moment(params.dateEnd, this.DATE_TIME_FORMAT) : moment().endOf('day')

                    params.dateStart = startDate.toISOString();
                    params.dateEnd = endDate.toISOString();

                    let limit = def.limits?.days;
                    if (this.app?.features?.page?.reporting?.dateLimitOverride) {
                        limit = 31; // always allow 31 days if the date limit override has been enabled
                    }

                    if (limit >= 31) {

                        // if a report is allowed to run for 31 days, override it's date selection with the full month date selections
                        // this is a hack, but since we can change the limit in the UI, and some reports are out of date, this fixes it 

                        if (!dateRange.values.find(x => x.key === 'thismonth')) {
                            dateRange.values.push({ 'key': 'thismonth', 'value': 'This Month' });
                        }

                        if (!dateRange.values.find(x => x.key === 'lastmonth')) {
                            dateRange.values.push({ 'key': 'lastmonth', 'value': 'Last Month' });
                        }

                    }


                    // for calendar year ranges we should start at 2018 (analytics only) and end at today as that is the only range that'll contain actual data
                    const customRange = dateRange.values.find(x => x.key === 'custom');
                    if (customRange) {
                        customRange.fields = customRange.fields.map(field => ({
                            ...field,
                            type: this.isSheduledReport ? 'daytime' : 'datetime',
                            min: def.type === 'analytics' && '2018',
                            max: moment().endOf('day'),
                        }));
                    }
                }
            }

            // only let keytelematics staff and staging users see the debug output format
            const showDebugOption = this.app.isSystemUser || this.app.user?.emailAddress?.includes('@keytelematics.com') || this.app.user?.emailAddress?.includes('@example.com');

            this.reportForm = {
                groups: [{
                    name: null, // this.i18n.instant('REPORTING.BUILDER.FORMS.REPORT'),
                    fields: [
                        { id: 'title', title: this.i18n.instant('REPORTING.BUILDER.FORMS.TITLE'), type: 'text', required: true, min: 1, max: 1000 },
                        {
                            id: 'outputFormat', title: this.i18n.instant('REPORTING.BUILDER.FORMS.OUTPUT_FORMAT'), type: 'combo', required: true,
                            values: [
                                { key: 'pdf', value: this.i18n.instant('REPORTING.BUILDER.FORMS.OUTPUT_FORMAT_PDF') },
                                { key: 'raw', value: this.i18n.instant('REPORTING.BUILDER.FORMS.OUTPUT_FORMAT_CSV') },
                                { key: 'raw_compact', value: this.i18n.instant('REPORTING.BUILDER.FORMS.OUTPUT_FORMAT_CSV_COMPACT') },
                                { key: 'csv', value: this.i18n.instant('REPORTING.BUILDER.FORMS.OUTPUT_FORMAT_CSV_FORMATTED') },
                                { key: 'xml', value: this.i18n.instant('REPORTING.BUILDER.FORMS.OUTPUT_FORMAT_XML') },
                                showDebugOption && { key: 'source', value: 'ZIP (Debug Data)' },
                            ].filter(x => x),
                        },
                    ].filter(x => x),
                }],
            };
            this.reportValues = {
                title: (this.report && this.report.title) || (cached && cached.params.title) || def.name,
                outputFormat: (this.report && this.report.outputFormat) || (cached && cached.params.outputFormat) || (def.template && def.template.config.outputFormat) || 'pdf',
            };

            const recurseDefaultValues = (fields: FormBuilderField[]) => {
                fields.forEach(field => {
                    const { key } = field.values && field.values[0] || {} as FormBuilderKeyValue;
                    const value = parameterValues[field.id] === 0 || parameterValues[field.id] ? parameterValues[field.id] : (field.value || key);


                    // convert field unit and value to client measurement settings where required
                    const unit = this.getUnit(field.id);
                    if (unit) {
                        field.unit = this.getLocalisedUnit(unit);
                    }

                    parameterValues[field.id] = typeof value === 'number' ? Math.round(value * (Math.pow(10, 2))) / Math.pow(10, 2) : value;

                    if (field.values) {
                        field.values.forEach(item => {
                            if (item.fields) {
                                recurseDefaultValues(item.fields);
                            }
                        });
                    }
                });
            };

            const setupAssetFilterFieldOptions = (fields: FormBuilderField[]) => {
                const excludeCategories = this.reportDefinition.type === 'dataset';
                const assetFilterField = fields.filter(field => field.type === 'assetfilter');
                assetFilterField.forEach(field => {
                    if (field.options) {
                        field.options.excludeCategories = excludeCategories;
                    } else {
                        field.options = {
                            excludeCategories,
                        };
                    }
                });
            };

            const parameterValues = { ...params };
            def.parameters.groups.forEach(group => {
                // get default values from fields where parameterValues[VALUE] is undefined
                recurseDefaultValues(group.fields);
                // setup asset filter with the report type being used
                setupAssetFilterFieldOptions(group.fields);
            });

            this.parametersForm = def.parameters;
            this.parameterValues = parameterValues;
            this.reportDefinition = def;

            this.hasLayout = (def.layout && ((def.layout.columns && def.layout.columns.length > 0) || (def.layout.grouping && def.layout.grouping.length > 0)));
            const styles = [
                { key: 'default', value: this.i18n.instant('REPORTING.BUILDER.FORMS.STYLE_DEFAULT') },
                { key: 'legacy', value: this.i18n.instant('REPORTING.BUILDER.FORMS.STYLE_LEGACY') },
            ];
            let layoutFields = [
                { id: 'orientation', title: this.i18n.instant('REPORTING.BUILDER.FORMS.ORIENTATION'), type: 'combo', values: [{ key: 'portrait', value: this.i18n.instant('REPORTING.BUILDER.FORMS.ORIENTATION_PORTRAIT') }, { key: 'landscape', value: this.i18n.instant('REPORTING.BUILDER.FORMS.ORIENTATION_LANDSCAPE') }] },
                { id: 'style', title: this.i18n.instant('REPORTING.BUILDER.FORMS.STYLE'), type: 'combo', values: styles },
            ];

            this.layoutFormValues['orientation'] = layout?.orientation || 'portrait';
            this.layoutFormValues['style'] = layout?.style || 'default';

            if (def.layout && def.layout.grouping && def.layout.grouping.length > 0) {
                layoutFields = [
                    ...layoutFields,
                    { id: 'grouping', title: this.i18n.instant('REPORTING.BUILDER.FORMS.GROUP_BY'), type: 'combo', values: def.layout.grouping.map(x => ({ key: x.id, value: x.name })) },
                    { id: 'pageBreak', title: this.i18n.instant('REPORTING.BUILDER.FORMS.PAGE_BREAK'), type: 'combo', values: [{ key: 'none', value: this.i18n.instant('REPORTING.BUILDER.FORMS.NONE') }, { key: 'groupAfter', value: this.i18n.instant('REPORTING.BUILDER.FORMS.AFTER_GROUP') }] },
                ].filter(x => x);
                this.layoutFormValues['grouping'] = layout?.grouping || def.layout.grouping[0].id;
                this.layoutFormValues['pageBreak'] = layout?.pageBreak || 'none';
            }

            this.layoutForm = {
                groups: [{
                    name: this.i18n.instant('REPORTING.BUILDER.FORMS.LAYOUT'),
                    fields: layoutFields,
                }],
            };
            this.columns = layout?.columns || (def.layout && def.layout.columns.filter(x => x.required || x.def));

            this.setFieldVisibility(this.showHiddenFields);

        }

        if (changes.showHiddenFields) {
            this.setFieldVisibility(this.showHiddenFields);
        }

    }

    setFieldVisibility(showHiddenFields: boolean) {
        this.showHiddenFields = showHiddenFields;
        if (this.parametersForm) {
            const template = this.reportDefinition.template;
            this.parametersForm.groups.forEach(group => {
                group.fields.forEach(field => {
                    field.visible = showHiddenFields || (template ? template.config.editableFields.includes(field.id) : true);
                });
                group.visible = showHiddenFields || (group.fields.reduce((sum, f) => sum + (f.visible ? 1 : 0), 0) > 0);
            });
            if (this.layoutForm && this.layoutForm.groups.length > 0) {
                this.layoutForm.groups[0].visible = showHiddenFields || (template ? template.config.editableFields.includes('layout') : true);
            }
            this.ref.markForCheck();
        }
    }

    getUnit(key: string): MeasurementUnitType {
        // unit should be converted if it contains the measurement
        return Object.values(MeasurementUnits).find(x => {
            const id = key.toLowerCase();
            // don't return items that contain time and date, it doesn't make sense to display those
            const exclude = ['date', 'time', 'overspeedprofile'];
            return id === 'date' || id === 'time' || (!exclude.find(y => id.includes(y)) && id.includes(x));
        });
    }

    getLocalisedUnit(type: string): string {
        if (type === 'speed') {
            return this.units.unitSymbol(type);
        } else {
            return this.units.unit(type);
        }
    }

}
