import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import {
    MapOption,
    MapOptionComponent,
    MapOptionZoneConfig,
    MapOptionZoneEditorComponent,
    MapOptionZoneRemoverComponent,
    MapOptionZoneSelectorComponent,
    ZoneEditorConfig,
    ZoneRemoverConfig,
    ZoneSelectorConfig
} from '../';
import { MapOptionService } from '../../map-option.service';
import { ROUTES } from '../../model';


type AvailableZoneConfig = ZoneEditorConfig | ZoneSelectorConfig | ZoneRemoverConfig;

@Component({
    styleUrls: ['./map-option-routes.component.scss'],
    templateUrl: './map-option-routes.component.html',
})
export class MapOptionRoutesComponent implements MapOptionComponent<MapOptionZoneConfig>, OnInit {

    static ID = 'map-option-routes-component';
    static ICON = 'scribble';
    static TITLE = 'LEAFLET.MAP_OPTIONS.ROUTES';

    data: MapOptionZoneConfig;
    options: MapOption<AvailableZoneConfig>[];

    compact$: Observable<boolean> = this.optionService.isCompact$;

    constructor(private optionService: MapOptionService) { }

    ngOnInit(): void {
        this.options = [
            this.data.zoneEdit && {
                component: MapOptionZoneSelectorComponent,
                data: {
                    selectableTypes: ROUTES,
                } as const,
                id: MapOptionZoneSelectorComponent.ID,
                title: 'LEAFLET.ZONES.EDIT_ROUTE',
                icon: 'edit'
            },
            this.data.zoneAdd && {
                component: MapOptionZoneEditorComponent,
                data: {
                    editorAction: 'zoneaddpolyline',
                } as const,
                id: MapOptionZoneEditorComponent.ID,
                title: 'LEAFLET.ZONES.NEW_ROUTE',
                icon: 'pen-line'
            },
            this.data.zoneDelete && {
                component: MapOptionZoneRemoverComponent,
                data: {
                    supportedTypes: ROUTES
                } as const,
                id: MapOptionZoneRemoverComponent.ID,
                title: 'LEAFLET.ZONES.REMOVE_ROUTE',
                icon: 'trash-can-xmark'
            }
        ].filter(x => x);
    }

}

