import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'earliestDate',
    standalone: true,
})
export class EarliestDatePipe implements PipeTransform {
    transform(date: Date, ...dates: Date[]): Date {
        const sorted = [date, ...dates].filter((date) => !!date).sort((a, b) => b.valueOf() - a.valueOf());
        return sorted[0];
    }
}
