import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class MapOptionLayerService {

    markerClustering = true;
    private _markerClusterSubject = new ReplaySubject<boolean>(1);

    markerClustering$ = this._markerClusterSubject.asObservable();

    setMarkerClustering(state: boolean) {
        this._markerClusterSubject.next(state);
    }

    toggleMarkerClustering() {
        this.markerClustering = !this.markerClustering;
        this.setMarkerClustering(this.markerClustering);
    }

}
