import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, WritableSignal, computed, signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IKuiModalAction, KuiIconModule, KuiModalModule } from 'app/key-ui';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { MapZonesService } from '../../map/map-zones.service';
import { MapSetService } from '../../map/mapset.service';
import { AssetSelectionService } from '../../selection/services/asset-selection.service';
import { AssetSelectionComponent, VideoAsset } from './asset-selection/asset-selection.component';
import { CameraSelectionComponent } from './camera-selection/camera-selection.component';
import { ClipBuilderComponent, ClipSettings } from './clip-builder/clip-builder.component';
import { VideoRequestSummaryComponent } from './request-summary/request-summary.component';

enum VideoRequestSteps {
    ASSET_SELECTION = 0,
    CLIP_SETTINGS = 1,
    CAMERA_SELECTION = 2,
    SUMMARY = 3
}

export interface VideoRequest {
    assetId: string, 
    date: string, 
    duration: number, 
    offset: number, 
    cameras: string[]
}

@Component({
    templateUrl: 'request.component.html',
    styleUrls: ['request.component.scss'],
    imports: [
        CommonModule,
        KuiModalModule,
        KuiIconModule,
        TranslateModule,
        ClipBuilderComponent,
        AssetSelectionComponent,
        CameraSelectionComponent,
        VideoRequestSummaryComponent,
    ],
    providers: [
        AssetSelectionService,
        MapSetService, 
        MapZonesService
    ],
    standalone: true,
})
export class RequestComponent extends KuiModalRefComponent<any> implements OnInit {

    step: WritableSignal<number> = signal(0);
    asset: WritableSignal<VideoAsset> = signal<VideoAsset>(undefined);
    clipSettings: WritableSignal<ClipSettings> = signal<ClipSettings>(undefined);
    cameras: WritableSignal<string[]> = signal<string[]>([]);

    readonly Steps = VideoRequestSteps;

    buttons: { [key: string]: IKuiModalAction } = {
        cancel: {
            text: this.i18n.instant('DIALOG.CANCEL'),
            style: 'secondary',
            keypress: 27,
            action: () => {
                this.actions.close();
            },
        },
        back: {
            text: this.i18n.instant('DIALOG.BACK'),
            style: 'secondary',
            action: () => {
                this.previous();
                return true;
            },
        },
        next: {
            text: this.i18n.instant('DIALOG.NEXT'),
            style: 'primary',
            action: () => {
                this.next();
                return true;
            },
        },
        finish: {
            text: this.i18n.instant('STATUS.DETAILS.ACTION_BUTTONS.REQUEST_VIDEO'),
            style: 'primary',
            action: () => {
                this.actions.apply({
                    assetId: this.asset().id, 
                    duration: this.clipSettings().duration,
                    offset: this.clipSettings().offset, 
                    cameras: this.cameras(), 
                    date: this.clipSettings().eventDate
                });
                return true;
            },
        },
    };

    modalActions = computed(() => {
        return [
            this.buttons.cancel,
            this.step() > 0 && this.buttons.back,
            this.step() !== VideoRequestSteps.SUMMARY && this.buttons.next,
            this.step() === VideoRequestSteps.SUMMARY && this.buttons.finish,
        ].filter(x => x);
    });

    @ViewChild(AssetSelectionComponent, { static: false }) assetSelectionComponent: AssetSelectionComponent;
    @ViewChild(ClipBuilderComponent, { static: false }) clipBuilderComponent: ClipBuilderComponent;
    @ViewChild(CameraSelectionComponent, { static: false }) cameraSelectionComponent: CameraSelectionComponent;

    constructor(private i18n: TranslateService, private assetSelectionService: AssetSelectionService) {
        super();
    }

    ngOnInit() {
        if (this.data?.assetId) {
            // Open the wizard to the clip settings after loading asset information
            this.assetSelectionService.getEntity(this.data.assetId).then(asset => {
                this.handleAssetSelected({
                    ...asset,
                    ...this.data.settings
                });
                this.step.set(VideoRequestSteps.CLIP_SETTINGS);
            });
        }
    }

    next(): void {
        if (this.isStepValid(this.step())) {
            if (this.step() === VideoRequestSteps.CLIP_SETTINGS && this.cameras().length > 0) {
                // Camera has already been selected, go straight to summary
                this.step.set(VideoRequestSteps.SUMMARY);
            } else {
                this.step.update(step => step + 1);
            }
        }
    }

    previous(): void {
        if (this.step() === VideoRequestSteps.SUMMARY && this.asset()?.cameras?.length === 1) {
            // there's only one camera, skip straight to clip settings
            this.step.set(VideoRequestSteps.CLIP_SETTINGS);
        } else {
            this.step.update(step => step - 1);
        }
    }

    handleAssetSelected(asset: VideoAsset) {
        this.asset.set(asset);

        // asset only has one camera, might as well select it now
        if (asset?.cameras?.length === 1) {
            this.cameras.set([asset.cameras[0].key]);
        }
    }

    handleSettingsChange(settings: ClipSettings) {
        this.clipSettings.set(settings);
    }

    handleCameraSelection(cameras: string[]) {
        this.cameras.set(cameras);
    }

    isStepValid(step: number): boolean {
        switch (step) {
            case VideoRequestSteps.ASSET_SELECTION: {
                return this.assetSelectionComponent.isValid();
            }
            case VideoRequestSteps.CLIP_SETTINGS: {
                return this.clipBuilderComponent.isValid();
            }
            case VideoRequestSteps.CAMERA_SELECTION: {
                return this.cameraSelectionComponent.isValid();
            }
        }
    }

}
