import { Component, HostBinding, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { TripFeedItem, LinkedAsset } from '../feed.model';
import { FeedEntryInfoIcon } from 'app/shared/components/feed/feed-entry-info/feed-entry-info.component';
import { MeasurementUnitsService } from 'app/services';
import { FeedEntryAction } from 'app/shared/components/feed/feed-entry-actions/feed-entry-actions.component';
import { AppService } from 'app/app.service';

@Component({
    selector: 'key-trip-feed-item',
    templateUrl: 'trip-feed-item.component.html',
    styleUrls: ['./trip-feed-item.component.scss'],
})
export class TripFeedItemComponent implements OnChanges {
    icons: FeedEntryInfoIcon[];
    tripTime: number;
    distance: string;
    active: boolean;
    title: string;
    titleStart: string;
    titleEnd: string;
    description: string;
    linkedAssets: LinkedAsset[];

    @Input() data: TripFeedItem;
    @Input() actions: FeedEntryAction[];

    @Output() primaryActionClicked: EventEmitter<any> = new EventEmitter();

    @HostBinding('class.feed-entry') feedEntryClass = true;

    layout = 'default';
    classNames: string[] = [];

    constructor(
        private app: AppService,
        private unitService: MeasurementUnitsService,
        private i18n: TranslateService
    ) {
        if (this.app?.features?.page?.overview?.layout === 'basic') {
            this.layout = 'basic';
            this.classNames.push('layout-right');
        }
     }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            this.icons = [];

            if (this.data.ratingScore) {
                this.icons = [this.getRatingScoreIcon(this.data.ratingScore)];
            }

            this.tripTime = moment.utc(this.data.dateEnd).tz(moment.defaultZone.name).diff(
                moment.utc(this.data.dateStart).tz(moment.defaultZone.name),
                'seconds'
            );

            this.distance = this.formatDistance(this.data.distance);

            this.active = (this.data.type === 'active') || (this.data.type === 'mixed' && this.data.distance > 0);

            this.linkedAssets = this.data.linkedAssets || [];

            const trim = (text: string): string => text.split(',')[0];

            if (this.data.locationStart && this.data.locationEnd) {
                this.title = this.getTranslatedTitle(this.data.locationStart, this.data.locationEnd);
                this.description = this.getTranslatedDescription(this.data.locationStart, this.data.locationEnd);

                this.titleStart = trim(this.data.locationStart);
                this.titleEnd = trim(this.data.locationEnd);

            }
        }
    }

    formatDistance(distance: number): string {
        if (this.data.type === 'inactive' || distance === undefined || distance === null) { return ''; }
        const unit = this.unitService.format(distance, 'distance');
        return unit.format.replace(unit.value + '.00', String(unit.value));
    }

    getRatingScoreIcon(score: number): FeedEntryInfoIcon {
        if (score === null || score === undefined) { return null; }

        const rating = { tooltip: this.i18n.instant('SHARED.TOOLTIP.DRIVER_RATING', { score: score }), name: 'star' };

        if (score > 2 * (1 / .03)) {
            return {
                id: 'rating-' + this.data.type,
                ...rating,
                color: 'success',
            };
        } else if (score > 1 / .03 && score < 2 * (1 / 0.03)) {
            return {
                id: 'rating-' + this.data.type,
                ...rating,
                color: 'warning',
            };
        } else {
            return {
                id: 'rating-' + this.data.type,
                ...rating,
                color: 'danger',
            };
        }
    }

    getTranslatedTitle(start: string, end: string): string {
        const trim = (text: string): string => text.split(',')[0];

        if (this.active) {
            return this.i18n.instant('SHARED.FEED_ENTRY.TRIP.START_TO_END', {
                start: `<span class="accent">${trim(start)}</span>`,
                end: `<span class="accent">${trim(end)}</span>`,
            });
        } else {
            return `<span class="accent">${trim(start)}</span>`;
        }
    }

    getTranslatedDescription(start: string, end: string): string {
        const translateKey = this.active ? 'SHARED.FEED_ENTRY.TRIP.ACTIVE' : 'SHARED.FEED_ENTRY.TRIP.INACTIVE';

        return this.i18n.instant(translateKey, {
            start: start,
            end: end,
        });
    }
}
