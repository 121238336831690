import * as moment from 'moment-timezone';
import { Component, HostBinding, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuditFeedItem } from '../feed.model';
import { FeedEntryInfoIcon } from 'app/shared/components/feed/feed-entry-info/feed-entry-info.component';
import { FeedEntryAction } from 'app/shared/components/feed/feed-entry-actions/feed-entry-actions.component';
import { getAndTranslateEventText } from 'app/shared/components/feed/event-feed/event-translation-dictionary';
import { MeasurementUnitsService } from 'app/services/measurement-units/measurement-units.service';

@Component({
    selector: 'key-audit-feed-item',
    templateUrl: 'audit-feed-item.component.html',
    styleUrls: ['audit-feed-item.component.scss'],
})
export class AuditFeedItemComponent implements OnChanges {

    icons: FeedEntryInfoIcon[];
    title: string;
    timeAgo: string;
    changes: string[];

    @Input() data: AuditFeedItem;
    @Input() actions: FeedEntryAction[];
    @Output() primaryActionClicked: EventEmitter<any> = new EventEmitter();

    @HostBinding('class.feed-entry') feedEntryClass = true;

    constructor(
        private i18n: TranslateService,
        private units: MeasurementUnitsService
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data && this.data) {
            this.icons = [];
            this.timeAgo = moment.utc(this.data.date).fromNow();
            this.title = this.getTranslatedText(this.data, 'description');
            this.changes = this.data.changes.map(change => {
                if (change.oldValue && change.newValue) {
                    return this.i18n.instant('SHARED.FEED_ENTRY.EVENT.OBJECT.CHANGES.MODIFIED', { name: change.name, oldValue: change.oldValue && change.oldValue.value || '', newValue: change.newValue && change.newValue.value || '' });
                } else {
                    if (change.newValue) {
                        return this.i18n.instant('SHARED.FEED_ENTRY.EVENT.OBJECT.CHANGES.ADDED', { name: change.name, newValue: change.newValue && change.newValue.value || '' });
                    } else {
                        return this.i18n.instant('SHARED.FEED_ENTRY.EVENT.OBJECT.CHANGES.REMOVED', { name: change.name, oldValue: change.oldValue && change.oldValue.value || '' });
                    }
                }
            });
        }
    }

    getTranslatedText(event: AuditFeedItem, textSection: 'title' | 'description' = 'title'): string {
        const e = { class: event.class, type: event.type, details: { user: event.user, object: event.entity, changes: event.changes } };
        return getAndTranslateEventText(textSection, e, this.i18n, this.units);
    }

}
