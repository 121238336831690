// https://videojs.com/guides/angular/

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';


    
// const EVENTS = [
//     // HTMLMediaElement events
//     'abort',
//     'canplay',
//     'canplaythrough',
//     'durationchange',
//     'emptied',
//     'ended',
//     'error',
//     'loadeddata',
//     'loadedmetadata',
//     'loadstart',
//     'pause',
//     'play',
//     'playing',
//     'progress',
//     'ratechange',
//     'seeked',
//     'seeking',
//     'stalled',
//     'suspend',
//     'timeupdate',
//     'volumechange',
//     'waiting',

//     // HTMLVideoElement events
//     'enterpictureinpicture',
//     'leavepictureinpicture',

//     // Element events
//     'fullscreenchange',
//     'resize',

//     // video.js events
//     'audioonlymodechange',
//     'audiopostermodechange',
//     'controlsdisabled',
//     'controlsenabled',
//     'debugon',
//     'debugoff',
//     'disablepictureinpicturechanged',
//     'dispose',
//     'enterFullWindow',
//     'error',
//     'exitFullWindow',
//     'firstplay',
//     'fullscreenerror',
//     'languagechange',
//     'loadedmetadata',
//     'loadstart',
//     'playerreset',
//     'playerresize',
//     'posterchange',
//     'ready',
//     'textdata',
//     'useractive',
//     'userinactive',
//     'usingcustomcontrols',
//     'usingnativecontrols',
// ];    
    
    
/** Chrome cannot play HLS videos on desktop, so we need this video player to deal with that */
@Component({
    selector: 'key-videojs-player',
    template: `
    <video #target class="video-js w-100 h-100" controls muted playsinline preload="none"></video>
  `,
    styleUrls: [
        // './vjs-player.component.css'
    ],
    encapsulation: ViewEncapsulation.None,
})
export class VjsPlayerComponent implements OnInit, OnDestroy {

    @ViewChild('target', { static: true }) target: ElementRef;

    // See options: https://videojs.com/guides/options
    @Input() options: {
        fluid: boolean,
        aspectRatio: string,
        autoplay: boolean,
        sources: {
            src: string,
            type: string,
        }[],
    };

    @Output() onReady = new EventEmitter();
    @Output() onError = new EventEmitter<Error>();
    @Output() onEnded = new EventEmitter();


    player: Player;

    constructor(
        
    ) { }

    ngOnInit() {

        this.target.nativeElement.addEventListener("error", (event) => {
            console.error('VIDEO ERROR', event.target.error);
            this.onError.emit(event.target.error);
        })

        this.player = videojs(this.target.nativeElement, this.options, () => {
            this.onReady.emit();
        });
        this.player.on('error', (error) => {
            console.error('VIDEO ERROR', error);
            this.onError.emit(error);   
        });

        // // use this to debug all events 
        // EVENTS.forEach(event => {
        //     this.player.on(event, (e) => console.log(event, e));
        // });

        // // this is the only event i've identified that fires when the stream stops from the server side
        // this.player.on('waiting', (e) => {
        //     console.log('VIDEO WAITING', e);
        //     this.onEnded.emit();
        // });


        // this.player.on("timeupdate", (event) => { //chrome fix

        //     console.log(event.currentTarget.currentTime, event.currentTarget.duration);

        //     // if (event.currentTarget.currentTime == event.currentTarget.duration) {
        //     //     console.log('video ended');
        //     // }
        // });


    }

    ngOnDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    }
}