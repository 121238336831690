import { OnDestroy, Output, EventEmitter, Input, Component } from '@angular/core';
import { BaseComponent } from '../base/base.component';

export type MapRouteStrategy = 'fastest' | 'shortest';
export interface MapRoute {
    id: string;
    strategy: MapRouteStrategy;
    distance: number;
    time: number;
    coords: number[][];
    description: string;
    active: boolean;
}

export interface MapBounds {
    left: number;
    top: number;
    right: number;
    bottom: number;
}

export interface MapMarker {
    id: string;
    name?: string;
    color?: string;
    flags?: {
        icon: string;
        color: string;
    }[];
    lon: number;
    lat: number;
    bounds?: MapBounds;
    draggable?: boolean;
    radius?: number; // kilometers

    links?: MapMarker[];

    dragged?: (lat: number, lon: number) => void;
    click?: (id: string) => void;
    getIcon: (selected: boolean) => { size: number, html: string };
    getBounds?: (bounds?: MapBounds) => MapBounds;
}

export type MapCoordinateAction = 'find_closest_asset'; 

export interface MapCoordinates {
    address?: string;
    lon?: number;
    lat?: number;
    speed?: number;
    error?: Error;
    action?: MapCoordinateAction;
}

export interface MapZone {
    id: string;
    name: string;
    color: string;
    type: 'location' | 'nogo' | 'keepin' | 'route' | 'zone';
    center: Point;
    radius: number;
    points?: Point[];
    modifiedDate: string; // used for cache invalidation
    interactive: boolean;
}

export interface PathOptions {
    color?: string;
    weight?: number;
    opacity?: number;
    fill?: boolean;
    fillColor?: string;
    fillOpacity?: number;
    buffer?: number; // buffer in meters
    tooltip?: string;
    dashArray?: string;
}

export interface Point {
    x: number;
    y: number;
}

export enum MapControls {
    zoom = 'zoom',
    fullscreen = 'fullscreen',
    recenter = 'recenter',
    simplezoneedit = 'simplezoneedit',
    zoneedit = 'zoneedit',
    zonedelete = 'zonedelete',
    zoneadd = 'zoneadd',
    layers = 'layers',
    measurements = 'measurements',
    googlemaps = 'googlemaps',
    search = 'search',
    coordinates = 'coordinates',
    routing = 'routing',
    findclosest = 'findclosest',
    mapsearch = 'mapsearch',
}

export interface MapButton {
    id: string;
    style: 'icon' | 'text' | 'both';
    title: string;
    icon: string;
    hiddenWhenFullscreen?: boolean;
}

export interface ZoneCreationEvent { 
    id: string;
    type: 'polygon' | 'polyline';
    points: Point[];
}

export interface ZoneEditEvent { 
    zones: { 
        id: string;
        points: Point[];
    }[];
}

export interface ZoneDeletionEvent {
    zones: {
        id: string;
    }[];
}

export interface PolygonEditEvent { 
    points: Point[];
}

export interface LayerClickEvent {
    layer: string;
    id: string;
    options?: any;
}

/**
 * Base component from which all map components needs to descend. Only reference this MapComponent class in map consumers to prevent
 * coupling to a specific map component implementation.
 */
@Component({
    selector: 'key-map-component',
    template: '',
})
export abstract class MapComponent extends BaseComponent implements OnDestroy {

    @Input() controls: MapControls[] = [
        MapControls.zoom,
        MapControls.fullscreen,
        MapControls.recenter,
        MapControls.googlemaps,
        MapControls.layers,
        MapControls.measurements,
    ];

    @Input() buttons: MapButton[] = [];

    @Output() onMapMoved = new EventEmitter<string>();
    @Output() onReset = new EventEmitter<void>();

    @Output() onPolygonEdited = new EventEmitter<PolygonEditEvent>();

    @Output() onZonesEdited = new EventEmitter<ZoneEditEvent>();
    @Output() onZonesDeleted = new EventEmitter<ZoneDeletionEvent>();

    @Output() onZoneCreated = new EventEmitter<ZoneCreationEvent>();

    @Output() onLayerDblClick = new EventEmitter<LayerClickEvent>();
    @Output() onLayerClick = new EventEmitter<LayerClickEvent>();

    @Output() onCoordinatesSelected = new EventEmitter<MapCoordinates>();
    @Output() onMarkerAdded = new EventEmitter<{ coordinates: MapCoordinates, id: string }>();

    @Output() onButtonClicked = new EventEmitter<string>();

    /**
     * keeps track of map component height
    */
    hostHeight: number;

    constructor() {
        super();
    }

    ngOnDestroy() {
        // no-op, can be overriden in descendants
    }

    /**
     * Causes the map control to resize itself and redraw all of its controls
     */
    abstract invalidate(): void;

    /**
     * Creates or updates an existing map marker
     */
    abstract setMarker(marker: MapMarker): void;

    /**
     * Clears all markers
     */
    abstract clearMarkers(): void;

    /**
     * Gets the bounds of all of the markers that have been added
     */
    abstract getMarkerBounds(): MapBounds;

    /**
     * Selects a marker with the specified id. Set pan to true to have the map pan to the marker location
     */
    abstract selectMarker(id: string, pan?: boolean, zoom?: number): void;

    /**
     * Zooms and pans the map to the passed lat/lon and zoom level
     */
    abstract zoomTo(lat: number, lon: number, zoom: number): void;

    /**
     * Zooms to the bounds of all of the markers on the map
     */
    abstract zoomToBounds(bounds: MapBounds): void;

    /**
     * Gets the bounds of the visible area on the map
     */
    abstract getBounds(): MapBounds;

    /**
     * Gets the center of the visible area on the map
     */
    abstract getCenter(): Point;

    /**
     * Gets the current map zoom level
     */
    abstract getZoom(): number;

    /**
     * Add/update street routes on the map
     */
    abstract setRoutes(routes: MapRoute[]);

    /**
     * remove street routes from the map
     */
    abstract clearRoutes(routes: MapRoute[]);

    /**
     * Add a map zone to the map
     */
    abstract addZone(zone: MapZone);

    /**
     * remove a zone from the map
     */
    abstract removeZone(id: string);

    /**
     * Update a zone
     */
    abstract updateZone(zone: MapZone);

    /**
     * Adds a polyline
     */
    abstract addPolyline(id: string, latLngs: Point[], options: PathOptions);

    /**
     * Removes a polyline
     */
    abstract removePolyline(id: string);

    /**
     * Add ability to get coordinates on the map
     */
    abstract getCoordinates();

    /**
     * Removes coordinates from the map
     */
    abstract removeCoordinates();

    /**
     * Disabled clustering of markers
     */
    abstract disableMarkerClustering();

    /**
     * Get the distance in pixels between two points
     */
    abstract getPixelDistance(start: Point, end: Point): number;

    /**
     * Gets the approximate zoom level that fits bounds
     */
    abstract getBoundsZoom(bounds: MapBounds): number;

    /**
     * Sets the zoom for the map
     */
    abstract setZoom(zoom: number);

    /**
     * Get bounds that fits given points
     */
    abstract getPointBounds(points: Point[]);

    /**
     * Toggle fullscreen mode
     */
    abstract toggleFullscreen();

    /**
     * Add a single marker to the map
     */
    abstract addMapMarker(marker: MapMarker);

    /**
     * Remove a single marker from the map
     */
    abstract removeMapMarker(marker: Partial<MapMarker>);
}
