<kui-modal size='lg'
    class="details-modal"
    [title]="title"
    (onClose)="detachModal()">

    <div class="videos d-flex flex-wrap">

        <div *ngIf="error"
            class="error flex-1 d-flex">
            <div class="centered flex-1 d-flex flex-stretch justify-content-center align-items-center">
                <kui-alert type="none"
                    class="key-alert-wrapper">
                    <kui-icon name="ban"
                        size="lg"></kui-icon>
                    <span>{{ error }}</span>
                </kui-alert>
            </div>
        </div>

        <div *ngIf="!error && loading"
            class="info flex-1 d-flex">
            <div class="centered flex-1 d-flex flex-stretch justify-content-center align-items-center">
                <kui-alert type="none"
                    class="inner key-alert-wrapper">
                     <kui-loader 
                        class="mr-4"
                         size="medium"></kui-loader>
                    <span>{{ "Requesting live video stream..." }}</span>
                </kui-alert>
            </div>
        </div>

        <div *ngIf="!error && video && ended"
            class="info flex-1 d-flex">
            <div class="centered flex-1 d-flex flex-stretch justify-content-center align-items-center">
                <kui-alert type="none"
                    class="inner key-alert-wrapper centered text-center">
                    <span>The current live stream has ended.<br/>Click the Refresh button below if you'd like to continue watching.</span>
                </kui-alert>
            </div>
        </div>

        <key-videojs-player *ngIf="video"
            class="w-100 h-100"
            [options]="video"
            (onError)="onLiveStreamError($event)"
            (onReady)="onLiveStreamReady()"
            >
        </key-videojs-player>
    
    </div>


    <div class="modal-footer d-flex">

        <div>
            <button 
                *ngIf="ended"
                kui-button
                class="mr-2"
                color="secondary"
                (click)="requestLiveStream()">
                <kui-icon name="refresh"></kui-icon>
                <span class="d-sm-inline-block ml-2">Refresh</span>
            </button>

            <button kui-button
                [color]="'primary'"
                (click)="detachModal()">
                <kui-icon name="times"></kui-icon>
                <span class="d-sm-inline-block ml-2"> {{ 'DIALOG.CLOSE' | translate }}</span>
            </button>
        </div>
    </div>

</kui-modal>