<kui-content-header [custom]="true"
    [sub]="true"
    class="datetime-bar no-border-top-nested">

    <div class="flex-stretch d-flex justify-content-center align-items-center">
        <kui-daterange
            #dateRange
            dateFormat="yyyy-MM-dd"
            timeStrategy="day-extents"
            inputStyle="none"
            [min]="minDate()"
            [max]="maxDate()"
            [noDatePlaceholder]="currentString()"
            [timePeriods]="timePeriods"
            (onChange)="handleChange($event)"
        >
        </kui-daterange>
    </div>

</kui-content-header>