import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class EventsService {

    constructor(
        private i18n: TranslateService
    ) {
    }

    /** this was sourced from the v1 API using https://keytelematics.app.us1.kt1.io/app/0000000000/api/lookup/eventtypes */
    readonly eventTypes = [
        'deviceaddressmessagetaskevent',
        'analoginputevent',
        'assetactivationevent',
        'assetstatechangeevent',
        'objectevent',
        'camerainputevent',
        'devicetextevent',
        'devicedialbackevent',
        'digitalinputevent',
        'digitaloutputevent',
        'valueinputevent',
        'excessiveidleevent',
        'fuelingevent',
        'geoborderevent',
        'geolockevent',
        'deviceoutputevent',
        'overspeedevent',
        'devicephototaskevent',
        'smsevent',
        'devicepollevent',
        'reminderevent',
        'reportcompletionevent',
        'tagevent',
        'taskevent',
        'devicetelemetrydownloadevent',
        'temperatureinputevent',
        'textmessageevent',
        'devicetextmessagetaskevent',
        'triggeredevent.alarm_active',
        'triggeredevent.alarm_armed',
        'triggeredevent.alarm_disarmed',
        'triggeredevent.analog_jump',
        'triggeredevent.analog_range',
        'triggeredevent.battery_active',
        'triggeredevent.battery_disconnect',
        'triggeredevent.battery_low',
        'triggeredevent.battery_reconnect',
        'triggeredevent.curfew_violation',
        'triggeredevent.excessive_stop_end',
        'triggeredevent.excessive_stop_start',
        'triggeredevent.gps_antenna_fault',
        'triggeredevent.gps_failure',
        'triggeredevent.gps_jammed',
        'triggeredevent.gps_signal_low',
        'triggeredevent.gsm_antenna_fault',
        'triggeredevent.gsm_jammed',
        'triggeredevent.gsm_signal_low',
        'triggeredevent.harsh_accel',
        'triggeredevent.harsh_brake',
        'triggeredevent.harsh_corner',
        'triggeredevent.impact',
        'triggeredevent.motion_end',
        'triggeredevent.motion_start',
        'triggeredevent.over_rpm',
        'triggeredevent.panic',
        'triggeredevent.power_disconnect',
        'triggeredevent.power_high',
        'triggeredevent.power_low',
        'triggeredevent.power_off',
        'triggeredevent.power_on',
        'triggeredevent.power_reconnect',
        'triggeredevent.tilt',
        'triggeredevent.towing',
        'triggeredevent.unauthorized_movement',
        'triggeredevent.vin_changed',
        'triggeredevent.voice_call_ended',
        'triggeredevent.voice_call_started',
        'triggeredevent.voice_callback',
        'deviceuploadevent',
        'deviceuserquerytaskevent',
        'zoneevent',
        // 'tripviolationevent'
    ];


    getEventTypes(flatten?: boolean): { id: string, name: string }[] {
        // remove everything after the fullstop of nested items and create a unique array
        const types = flatten ? [...(new Set(this.eventTypes.map(x => {
            const [event, nested] = x.split('.');
            // add _flat so that we know to translate the word and not its children
            return `${event}${nested ? '_flat' : ''}`;
        })))] : this.eventTypes;

        return types.map(id => {
            const name = this.i18n.instant(`EVENTS.NAMES.${id.toUpperCase()}`);
            return {
                id: id.split('_flat')[0], // remove _flat to make this id legit again
                name,
            };
        });
    }


}
