
import { FormBuilderField, FormBuilderKeyValue } from './shared/components/form-builder';

export const APP_FEATURE_FLAGS = 'fleet-ui-v2';

export const AppFeatureDefaults = {
    // When adding a feature flag that needs to make it's way to the admin features page, add it to the following 
    //  structure. Values added here are automatically parsed to create editors in Administration.
    // NOTE: you'll also need to add translation text in the `en.json` file!
    page: {
        overview: {
            enabled: true,
            name: '',
            icon: '',
            layout: 'advanced',
            show: {
                activity: true,
                events: true,
                alerts: true,
                videos: false,
                sharing: false,
                tasks: false,
                editing: false,
            },
            pageSize: 300,
            customPanels: '',
            group: '',
            order: 10,
        },
        dashboards: {
            enabled: false,
            show: {
                hours: false,
            },
            group: '',
            order: 40,
        },
        videos: {
            enabled: false,
            maxDuration: 60,
            group: '',
            order: 30,
        },
        alerts: {
            enabled: false,
            feedLoadLimit: 30,
            actions: '', // an array of allowed action types, separated by commas
            group: '',
            order: 20,
        },
        replay: {
            enabled: false,
            // customPanels: '',
            group: '',
            order: 50,
        },
        mapsearch: {
            enabled: false,
            group: '',
            order: 60,
        },
        reporting: {
            enabled: false,
            show: {
                analytics: false,
            },
            reports: '', // an array of guid's separated by commas
            dateLimitOverride: false, // enable to remove the standard report date limits and set all to 31 days (not recommended)
            group: '',
            order: 70,
        },
        external: {
            enabled: false,
            tabs: '', // a list of name:url pairs separated by newlines
        },
        admin: {
            enabled: false,
            customPanels: '',
            show: {
                fuelCards: false,
            },
            availableAssetTypes: '', // an array of guid's separated by commas
            group: '',
            order: 80,
        },
    },
};


export const PAGE_ICONS = {
    overview: 'compass',
    dashboards: 'analytics',
    alerts: 'exclamation-triangle',
    videos: 'photo-video',
    replay: 'history',
    mapsearch: 'search-location',
    reporting: 'chart-pie',
    admin: 'cog',
    demo: 'palette',
    system: 'heart-rate',
    external: 'browser',
};


export interface AppFeatureFlags {
    [page: string]: AppFeatureSection;
}

export const AppFeatures: AppFeatureFlags = {
    // will be populated by the code at the bottom
};

export const AppFeatureFlagDefaults = {
    // will be populated by the code at the bottom
};


export interface FeatureSection {
    page: string;
    icon: string;
    fields: FormBuilderField[];
}

export interface FeatureSections {
    [page: string]: FeatureSection;
}


export class AppFeatureSection implements FeatureSection {

    icon = PAGE_ICONS[this.page.split('-')[1]];
    fields: FormBuilderField[] = []; // should only ever be one!
    subfields: FormBuilderField[] = [];

    constructor(public page: string, defaultState: boolean) {
        this.fields.push({
            id: this.page + '-enabled',
            title: 'PAGE',
            type: 'combo',
            required: true,
            values: [
                { key: 'null', value: 'INHERIT' },
                { key: 'true', value: 'ENABLED', fields: this.subfields },
                { key: 'false', value: 'DISABLED' },
            ],
        });
        this.addDefault('enabled', defaultState);
        AppFeatures[this.page] = this;
    }

    private addDefault(id: string, defaultValue: any) {
        AppFeatureFlagDefaults[`${this.page}-${id}`] = defaultValue;
    }

    addEnabledToggle(id: string, defaultValue: boolean): AppFeatureSection {
        this.addDefault(id, defaultValue);
        this.subfields.push({ id: `${this.page}-${id}`, title: id.toUpperCase(), type: 'combo', required: true, values: [{ key: 'null', value: 'INHERIT' }, { key: 'true', value: 'ENABLED' }, { key: 'false', value: 'DISABLED' }] });
        return this;
    }

    addCombo(id: string, values: FormBuilderKeyValue[], defaultValue: string): AppFeatureSection {
        this.addDefault(id, defaultValue);
        this.subfields.push({ id: `${this.page}-${id}`, title: id.toUpperCase(), type: 'combo', required: true, values: values });
        return this;
    }

    addNumber(id: string, min: number, max: number, defaultValue: number): AppFeatureSection {
        this.addDefault(id, defaultValue);
        this.subfields.push({
            id: `${this.page}-${id}`,
            title: id.toUpperCase(),
            type: 'number',
            required: false,
            min: min,
            max: max,
        } as any);
        return this;
    }

    addCheckList(id: string, lookupKey: string, defaultValue: string[]): AppFeatureSection {
        this.addDefault(id, defaultValue.join(','));
        this.subfields.push({
            id: `${this.page}-${id}`,
            title: id.toUpperCase(),
            type: 'checklist',
            required: false,
            values: [],
            valueLookup: lookupKey,
        } as any);
        return this;
    }


    addMemo(id: string, defaultValue: string[]): AppFeatureSection {
        this.addDefault(id, defaultValue.join('\n'));
        this.subfields.push({
            id: `${this.page}-${id}`,
            title: id.toUpperCase(),
            type: 'memo',
            required: false,
        } as any);
        return this;
    }

    addText(id: string, defaultValue: string, required: boolean): AppFeatureSection {
        this.addDefault(id, defaultValue);
        this.subfields.push({
            id: `${this.page}-${id}`,
            title: id.toUpperCase(),
            type: 'text',
            required: required,
        } as any);
        return this;
    }

    addList(id: string): AppFeatureSection {
        this.addDefault(id, '');
        this.subfields.push({
            id: `${this.page}-${id}`,
            title: id.toUpperCase(),
            type: 'list',
            required: false,
        } as any);
        return this;
    }

}


function AppFeature(pageId: string, defaultState: boolean): AppFeatureSection {
    return new AppFeatureSection('page-' + pageId, defaultState);
}

/** This function is called by the APP_INITIALIZER hook before the application is initialized */
export function InitializeFeatureDefaults() {

    Object.keys(AppFeatureDefaults.page).forEach(name => {
        const section = AppFeature(name, AppFeatureDefaults.page[name].enabled);
        Object.keys(AppFeatureDefaults.page[name].show || {}).forEach(feature => {
            section.addEnabledToggle(`show-` + feature, AppFeatureDefaults.page[name].show[feature]);
        });
        if (AppFeatureDefaults.page[name].group !== undefined) {
            section.addText('group', AppFeatureDefaults.page[name].group, false);
        }
        if (AppFeatureDefaults.page[name].order) {
            section.addNumber('order', 0, 1000, AppFeatureDefaults.page[name].order);
        }
    });

    AppFeatures['page-overview'].addNumber('pageSize', 5, 1000, 300);
    AppFeatures['page-overview'].addCombo('layout', [{ key: 'advanced', value: 'ADVANCED' }, { key: 'basic', value: 'BASIC' }], 'advanced');
    AppFeatures['page-overview'].addText('name', '', false);
    AppFeatures['page-overview'].addText('icon', '', false);
    
    AppFeatures['page-reporting'].addCheckList('reports', 'reports', []);
    AppFeatures['page-reporting'].addEnabledToggle('dateLimitOverride', false);
    
    AppFeatures['page-alerts'].addNumber('feedLoadLimit', 30, 500, 30); // max set by API
    AppFeatures['page-alerts'].addCheckList('actions', 'alert-actions', []);

    AppFeatures['page-videos'].addNumber('maxDuration', 1, 600, 60);

    AppFeatures['page-external'].addList('tabs');
    AppFeatures['page-admin'].addCheckList('availableAssetTypes', 'assetTypes', []);

    AppFeatures['page-admin'].addList('customPanels');
    AppFeatures['page-overview'].addList('customPanels');

}
