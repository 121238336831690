<div class="app-menu {{orientation}}">
    <div class="app-menu-overlay"
        (click)="toggleMobile(false)"
        (panleft)="toggleMobile(false)"></div>
    <div class="app-menu-content flex-container d-flex"
        (panleft)="toggleMobile(false)">
        <ng-content select="kui-menu-brand"></ng-content>
        <ng-content select="kui-user"></ng-content>
        <div class="d-flex flex-column flex-1 app-menu-content-inner">
            <ng-content select="kui-nav"></ng-content>
            <ng-content></ng-content>
        </div>
        <kui-icon *ngIf="canToggleCollapse"
            kui-action
            class="menu-collapse-arrow overflow-hidden"
            name="chevron-left"
            (click)="toggleCollapse(!isToggledCollapse)"></kui-icon>
    </div>
</div>