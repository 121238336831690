import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { AppService } from 'app/app.service';
import { MatchMediaService, LocalStore } from 'app/services';

import { BaseComponent } from 'app/shared/components';

import * as moment from 'moment-timezone';
import { Observable, fromEvent, interval } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { MediaInfoResponse } from '@key-telematics/fleet-api-client';
import { FilterPanelService } from 'app/shared/components/filter-panel/filter-panel.service';
import { DateRange } from 'app/shared/utils/dates';

export interface ListItem extends MediaInfoResponse {
    listFilter?: any;
    [key: string]: any;
}

export const VideoListLocalStore = new LocalStore('video-list-component');

@Component({
    selector: 'key-video-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    providers: [{ provide: LocalStore, useValue: VideoListLocalStore }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoListComponent extends BaseComponent implements OnInit {

    isMobile: boolean;

    videoListing: ListItem[] = [];

    windowResize: Observable<Event> = fromEvent(window, 'resize').pipe(
        takeUntil(this.destroyed),
        startWith(new CustomEvent('resize')),
        debounceTime(100)
    );

    @Input() title: string;
    @Input() show: boolean;
    @Input() count: number;
    @Input() listLoaded: boolean;
    @Input() selectedId: string;
    @Input() errorMessage: string;
    @Input() page = 1;
    @Input() showDateSelect = true;
    @Input() noVideosMessage: string;

    @Input() dateRange: DateRange;
    @Input() showFilterByValue = true;

    limit = 20;

    get pageCount(): number {
        return (this.count / this.limit);
    }

    @Input() set videos(videos: MediaInfoResponse[]) {
        this.videoListing = videos.map(x => this.createListItem(x));
    }

    @Output() itemSelected = new EventEmitter<MediaInfoResponse>();
    @Output() pageChanged = new EventEmitter<number>();
    @Output() requestVideo = new EventEmitter();

    constructor(
        public app: AppService,
        private ref: ChangeDetectorRef,
        private matchMedia: MatchMediaService,
        private filterService: FilterPanelService
    ) {
        super();

        this.on(this.matchMedia.isDesktop, desktop => {
            this.isMobile = !desktop;
        });

        this.on(this.filterService.filters$, f => {
            this.page = f.page || 1;
            this.limit = f.limit || this.limit;
            this.ref.markForCheck();
        });

        this.on(this.filterService.total$, total => {
            this.count = total || 0;
            this.ref.markForCheck();
        });

    }

    ngOnInit() {
        interval(30e3)
            .pipe(takeUntil(this.destroyed))
            .subscribe(() => {
                // This is so the html can be re-evaluated and we can get the correct date time ago.
                this.ref.markForCheck();
            });
    }

    createListItem(video: MediaInfoResponse): ListItem {
        const result = {
            ...video,
            name: video.asset.name,
            last_update: this.createLastUpdate(video),
            listFilter: video.asset.name,
            filterByValue: this.showFilterByValue,
        };
        return result;
    }

    createLastUpdate(video: MediaInfoResponse) {
        if (video.date) {
            const dateEpoch = this.getDateEpoch(video.date);
            return { // Transform into a KuiRow-compatible format
                display: this.getDisplayDate(video),
                field: dateEpoch,
                value: dateEpoch,
            };
        }
        return { display: '', field: '', value: '' };
    }

    getDisplayDate(video: MediaInfoResponse): string {
        return video.date ? moment.utc(video.date).fromNow() : '';
    }

    reset() {
        this.videoListing = [];
    }

    getDateEpoch(date: string): string {
        return moment.utc(date, 'YYYYMMDDHHmm').tz(moment.defaultZone.name).format('x');
    }

    onPageChanged(page: number) {
        this.pageChanged.emit(page);
    }

    onDateSelect(date: string) {
        this.dateRange = {
            start: moment(date).startOf('day').toISOString(),
            end: moment(date).endOf('day').toISOString(),
        };
        this.filterService.update({
            date: this.dateRange,
        });

    }
}
