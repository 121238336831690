import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KuiModule } from 'app/key-ui';
import { KuiLoaderModule } from 'app/key-ui/loader/loader.module';

import { FeedComponent } from './feed.component';
import { TripFeedItemComponent } from './trip-feed/trip-feed-item.component';
import { TripPreviewComponent } from './trip-feed/preview/preview.component';
import { TripPreviewMapComponent } from './trip-feed/preview/map/map.component';
import { EventFeedItemComponent } from './event-feed/event-feed-item.component';
import { KeyFeedEntryInfoComponent } from './feed-entry-info/feed-entry-info.component';
import { EventDetailsComponent } from './event-feed/details/details.component';
import { EventDetailsSummaryComponent } from './event-feed/details/summary/summary.component';
import { EventDetailsContactsComponent } from './event-feed/details/contacts/contacts.component';
import { EventDetailsMapComponent } from './event-feed/details/map/map.component';

import { PipeModule } from 'app/shared/pipes/pipe.module';
import { GetKeyPipe } from 'app/shared/pipes/getkey.pipe';
import { EventFeedCommentModalComponent } from './event-feed/details/comment/comment.component';
import { FormsModule } from '@angular/forms';
import { KeyMapModule } from 'app/shared/components/map/map.module';
import { KeyCardsModule } from 'app/shared/components/cards/cards.module';
import { KeySelectionModule } from 'app/shared/components/selection/selection.module';
import { EventDetailsModalComponent } from 'app/shared/components/feed/modal/modal.component';
import { KeyFeedEntryActionsComponent } from 'app/shared/components/feed/feed-entry-actions/feed-entry-actions.component';
import { KeyContactsModule } from 'app/shared/components/contacts/contacts.module';
import { KeyMediaModule } from 'app/shared/components/media/media.module';
import { AuditFeedItemComponent } from './audit-feed/audit-feed-item.component';
import { NotificationFeedItemComponent } from './notification-feed/notification-feed-item.component';
import { EventDetailsIFrameComponent } from './event-feed/details/iframe/iframe.component';
import { EventFeedItemDescriptionComponent } from './event-feed/description/event-feed-item-description.component';
import { LogFeedItemComponent } from './log-feed/log-feed-item.component';
import { KeyIntersectionObserverDirective } from 'app/shared/directives';

const COMPONENTS = [
    FeedComponent,
    AuditFeedItemComponent,
    NotificationFeedItemComponent,
    TripFeedItemComponent,
    LogFeedItemComponent,
    TripPreviewComponent,
    TripPreviewMapComponent,
    EventFeedItemComponent,
    EventFeedItemDescriptionComponent,
    KeyFeedEntryInfoComponent,
    KeyFeedEntryActionsComponent,
    EventFeedCommentModalComponent,
    EventDetailsComponent,
    EventDetailsSummaryComponent,
    EventDetailsContactsComponent,
    EventDetailsMapComponent,
    EventDetailsModalComponent,
    EventDetailsIFrameComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        KuiModule,
        TranslateModule.forChild(),
        KuiLoaderModule,
        PipeModule.forRoot(),
        KeyMapModule,
        KeyCardsModule,
        KeySelectionModule,
        KeyContactsModule,
        KeyMediaModule,
        KeyIntersectionObserverDirective
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS,
    providers: [GetKeyPipe]
})
export class KeyFeedModule { }
