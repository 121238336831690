import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { getTimeBuckets } from 'app/shared/utils/time-buckets';
import { TripRating, TelemetryResponse, EventResponse, IdNameType, TripResponse } from '@key-telematics/fleet-api-client';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { EventFeedItem } from 'app/shared/components/feed/feed.model';
import * as Bluebird from 'bluebird';

export interface TripLinkedAsset extends IdNameType {
    icon?: { name: string, color: string };
}

export interface TripArgs {
    assetId: string;
    dateStart: string;
    dateEnd: string;
    telemetry?: TelemetryResponse[];
    events?: EventFeedItem[];
    hidden?: boolean;
}

export interface TripItem extends TripArgs {
    id: string;
    tripType: 'inactive' | 'active' | 'mixed';
    lonStart: number;
    latStart: number;
    addressStart: string;
    lonEnd: number;
    latEnd: number;
    addressEnd: string;
    driveTime: number;
    idleTime: number;
    distance: number;
    duration: number;
    rating?: TripRating;
    linkedAssets?: TripLinkedAsset[];
    inprogress?: boolean;
}

export function getTripId(trip: Pick<TripArgs, 'dateStart'>): string {
    return moment.utc(trip.dateStart).format('DDHHmmss');
}

export function tripMatchesId(trip: TripArgs, id: string): boolean {
    return getTripId(trip) === id;
}

@Injectable()
export class TripsService {
    private promiseCache: { [key: string]: Promise<any> } = {};

    constructor(
        public app: AppService,
        private i18n: TranslateService
    ) {
    }


    mapTrip(x: TripResponse): TripItem {
        return {
            id: x.id,
            assetId: x.asset.id,
            tripType: x.tripType,
            dateStart: x.dateStart,
            lonStart: x.start && x.start.lon,
            latStart: x.start && x.start.lat,
            addressStart: (x.start && x.start.address) || this.i18n.instant('SHARED.UNKNOWN_LOCATION'),
            dateEnd: x.dateEnd,
            latEnd: x.end && x.end.lat,
            lonEnd: x.end && x.end.lon,
            addressEnd: (x.end && x.end.address) || this.i18n.instant('SHARED.UNKNOWN_LOCATION'),
            rating: x.rating,
            driveTime: x.stats && x.stats.driveTime,
            idleTime: x.stats && x.stats.idleTime,
            distance: x.stats && x.stats.distance,
            duration: this.getTripDurationSeconds(x.dateStart, x.dateEnd),
            linkedAssets: x.linkedAssets,
            inprogress: false,
        };
    }


    loadTrips(assetId: string, start: string, end: string): Promise<TripItem[]> {
        this.clearCache();
        return this.app.api.data.getTripHistory(assetId, start, end)
            .then(res => {
                return res.items.map(x => this.mapTrip(x));
            });
    }

    loadTripTelemetry(trip: TripArgs): PromiseLike<TelemetryResponse[]> {
        return Bluebird.map(
            getTimeBuckets(trip.dateStart, trip.dateEnd, 24 * 60 ** 2),
            (dates) => {
                return this.loadSingleTripTelemetry({
                    ...trip,
                    dateStart: dates[0],
                    dateEnd: dates[1],
                });
            },
            {
                concurrency: 1,
            }
        )
            .then(telemetries => telemetries.reduce((all, current) => all.concat(current), []));
    }

    loadSingleTripTelemetry(trip: TripArgs): Promise<TelemetryResponse[]> {
        const key = `getTelemetryHistory/${trip.assetId}/${trip.dateStart}/${trip.dateEnd}`;
        this.promiseCache[key] = this.promiseCache[key] || this.app.api.data.getTelemetryHistory(trip.assetId, trip.dateStart, trip.dateEnd);
        return this.promiseCache[key].then(res => {
            return res.items;
        });
    }

    loadTripEvents(trip: TripArgs): PromiseLike<EventResponse[]> {
        return Bluebird.map(
            getTimeBuckets(trip.dateStart, trip.dateEnd, 24 * 60 ** 2),
            dates => {
                return this.loadSingleTripEvents({
                    ...trip,
                    dateStart: dates[0],
                    dateEnd: dates[1],
                });
            },
            {
                concurrency: 1,
            }
        ).then(telemetries =>
            telemetries.reduce((all, current) => all.concat(current), [])
        );
    }

    loadSingleTripEvents(trip: TripArgs): Promise<EventResponse[]> {
        const key = `getEventHistory/${trip.assetId}/${trip.dateStart}/${trip.dateEnd}`;
        this.promiseCache[key] = this.promiseCache[key] || this.app.api.data.getEventHistory(trip.assetId, trip.dateStart, trip.dateEnd);
        return this.promiseCache[key].then(res => {
            return res.items;
        });
    }

    getTripDurationSeconds(start: string, end: string): number {
        const s = moment.utc(start).tz(moment.defaultZone.name);
        const e = moment.utc(end).tz(moment.defaultZone.name);
        return e.diff(s) / 1000;
    }

    clearCache() {
        this.promiseCache = {};
    }
}
