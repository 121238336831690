<div *ngIf="field" [ngSwitch]="field.type" [class.hidden]="field.visible === false">

    <div class="form-group" [class.inline]="inline">

        <label for="{{ field.id }}" class="form-label mr-3">
            {{ field.title }}
            <span *ngIf="field.unit" class="units">{{field.unit}}</span>
        </label>
        <div class="form-field" *ngIf="loading">
            <div class="border p-2">
                <kui-loader size="small"></kui-loader>
            </div>
        </div>
        <div class="form-field" *ngIf="errorMessage">
            <div class="text-danger border p-2">{{ errorMessage }}</div>

        </div>
        <div class="form-field"
            [class.field-align-right]="field.type === 'toggle' || field.type === 'color' || field.type === 'radio' || field.type === 'swatches'"
            *ngIf="!loading && !errorMessage">

            <div class="d-flex">
                <div [class]="field.type !== 'swatches' && field.type !== 'color' ? 'flex-1' : ''">
                    <key-form-builder-text-field #fieldComponent *ngSwitchCase="'text'" [field]="field" [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-text-field>
                    <key-form-builder-text-field #fieldComponent *ngSwitchCase="'password'" [field]="field" [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-text-field>

                    <key-form-builder-number-field #fieldComponent *ngSwitchCase="'number'" [field]="field" [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-number-field>

                    <key-form-builder-memo-field #fieldComponent *ngSwitchCase="'memo'" [field]="field" [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-memo-field>

                    <key-form-builder-daytime-field #fieldComponent *ngSwitchCase="'daytime'" [field]="field" [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-daytime-field>
                    
                    <key-form-builder-datetime-field #fieldComponent *ngSwitchCase="'datetime'" [field]="field" [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-datetime-field>

                    <key-form-builder-datetime-field #fieldComponent *ngSwitchCase="'date'" [field]="field" [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-datetime-field>

                    <key-form-builder-datetime-field #fieldComponent *ngSwitchCase="'time'" [field]="field" [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-datetime-field>

                    <key-form-builder-combo-field #fieldComponent *ngSwitchCase="'combo'" [field]="field" [values]="values" [error]="error" (onSelected)="comboChanged($event?.value, $event?.dirty)"></key-form-builder-combo-field>

                    <key-form-builder-checkbox-field #fieldComponent *ngSwitchCase="'checkbox'" [field]="field" [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"> </key-form-builder-checkbox-field>

                    <key-form-builder-checklist-field #fieldComponent *ngSwitchCase="'checklist'" [field]="field" [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-checklist-field>

                    <key-form-builder-tags-field #fieldComponent *ngSwitchCase="'tags'" [field]="field" [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-tags-field>

                    <key-form-builder-asset-filter-field #fieldComponent *ngSwitchCase="'assetfilter'" [field]="field"[error]="error" [values]="values" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-asset-filter-field>

                    <key-form-builder-search-select-field #fieldComponent *ngSwitchCase="'searchselect'" [field]="field"[error]="error" [values]="values" (onChange)="searchSelectChanged($event?.dirty)"></key-form-builder-search-select-field>

                    <key-form-builder-zone-filter-field #fieldComponent *ngSwitchCase="'zonefilter'" [field]="field"[error]="error" [values]="values" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-zone-filter-field>

                    <key-form-builder-client-filter-field #fieldComponent *ngSwitchCase="'clientfilter'" [field]="field" [error]="error" [values]="values" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-client-filter-field>

                    <key-form-builder-color-field #fieldComponent *ngSwitchCase="'color'" [field]="field" [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-color-field>

                    <key-form-builder-color-combo-field #fieldComponent *ngSwitchCase="'colorcombo'" [field]="field" [values]="values" [error]="error" (onSelected)="emitFieldChange($event?.dirty)"></key-form-builder-color-combo-field>

                    <key-form-builder-toggle-field #fieldComponent *ngSwitchCase="'toggle'" [field]="field" [values]="values" [error]="error" (onChange)="toggleChanged($event?.value, $event?.dirty)"></key-form-builder-toggle-field>

                    <key-form-builder-radio-field #fieldComponent *ngSwitchCase="'radio'" [field]="field" [values]="values" [error]="error" (onChange)="comboChanged($event?.value, $event?.dirty)"></key-form-builder-radio-field>

                    <key-form-builder-minmax-field #fieldComponent *ngSwitchCase="'minmax'" [field]="field"  [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-minmax-field>

                    <key-form-builder-list-field #fieldComponent *ngSwitchCase="'list'" [field]="field" [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-list-field>

                    <key-form-builder-eventfilter-field #fieldComponent *ngSwitchCase="'eventfilter'" [field]="field" [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-eventfilter-field>

                    <key-form-builder-geofilter-field #fieldComponent
                        *ngSwitchCase="'geofilter'"
                        [field]="field"
                        [values]="values"
                        [error]="error"
                        (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-geofilter-field>

                    <key-form-builder-image-field #fieldComponent *ngSwitchCase="'image'" [field]="field" [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-image-field>

                    <key-form-builder-swatches-field #fieldComponent *ngSwitchCase="'swatches'" [field]="field" [values]="values" [error]="error" (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-swatches-field>

                    <key-form-builder-text-field #fieldComponent 
                        *ngSwitchCase="'url'" 
                        [field]="field" 
                        [values]="values" 
                        [error]="error" 
                        pattern="^https?:\/\/.*" 
                        patternErrorMessage="FORMS.URL.INVALID_PATTERN" 
                        (onChange)="emitFieldChange($event?.dirty)"></key-form-builder-text-field>
                </div>
                <div *ngIf="field.actions && field.type !== 'list' && field.type !== 'tags'"
                    [ngClass]="{
                        'align-items-center': field.type !== 'swatches',
                        'align-items-start': field.type === 'swatches',
                        'pt-2': field.type === 'swatches'
                    }"
                    class="d-flex justify-content-center pl-2">
                    <kui-icon *ngFor="let action of field.actions"
                        size="sm"
                        kui-action
                        [name]="action.icon"
                        [kui-tooltip]="action.name"
                        (click)="onActionClick(action)"
                        >
                    </kui-icon>
                </div>
            </div>
            <small *ngIf="field.description" class="form-text muted">
                <markdown [data]="field.description"></markdown>
            </small>
        </div>

    </div>

    <key-form-builder-field #subFieldComponent *ngFor="let subfield of subfields" [inline]="inline" [field]="subfield" [values]="values" (onChange)="emitFieldChange($event?.dirty, subfield)"></key-form-builder-field>

</div>